import React from 'react';
import _ from 'lodash';
import Tiles from "./Tiles";
import styled from 'styled-components';

const BannerContainer = styled.div`  
    padding: 1.5rem;
    margin-bottom: 1.05em;
    font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.4;
    color: #524c61;
    border: 1px solid #e5ebf0;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12), 0 2px 4px 0 rgba(19,26,31,0.22);
    background-color: white;
    height: fit-content;
    width: auto;
`;

const Title = styled.div`  
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #333;
    display: block;
`;

const Text = styled.p`  
    margin-bottom: 14.4px;
    font-size: 14px;
    text-align: center;
    color: #676767;
    font-weight: 300;
    margin: 10px 0 20px 0;
`;

class Banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BannerContainer>
                <Title>{this.props.title}</Title>
                <Text>{this.props.promotionTextMsg}</Text>
                <Tiles
                    tiles={this.props.tiles}
                    ctaText={this.props.ctaText}
                    logoImagesHeight={this.props.logoImagesHeight}
                    recordClickout={this.props.recordClickout}
                    sidePlacementEvent={this.props.sidePlacementEvent}
                />
            </BannerContainer>
        )
    }


}
export default Banner;

