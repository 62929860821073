import React from 'react';

import styled from 'styled-components'
import Carrier from "./Carrier";
import Duration from "./Duration";
import DateTime from "./DateTime";
import TimeWithOffset from "./TimeWithOffset";
import Stops from "./Stops";
import Station from "./Station";
import StopLine from "./StopLine";

const Summery = styled.div`
    padding: 1.5rem;
    padding: .75rem;
    background-color: #fff;
    cursor: pointer;
`;

const LegContainer = styled.div`
    display: grid; 
    grid-template-columns: 0.4fr 1.2fr 1.2fr 1.2fr;
    grid-template-rows: repeat(2, 1fr);
    align-items: center; 
`;

const DepartureTime = styled.div`
    grid-area: 1 / 2 / 2 / 3;
    justify-self: end; 
    font-weight: 600; 
`;

const OriginStation = styled.div`
    grid-area: 2 / 2 / 3 / 3;
    justify-self: end; 
`;

const LegInfographics = styled.div` 
    grid-area: 1 / 3 / 3 / 4; 
`;

const DurationContainer = styled.div`
    text-align: center; 
`;

const StopsContainer = styled.div`
    text-align: center; 
    font-size: 0.75rem;
`;

const DestinationStation = styled.div`
    grid-area: 2 / 4 / 3 / 5; 
    justify-self: start; 
`;

const ArrivalTime = styled.div`
    grid-area: 1 / 4 / 2 / 5;
    justify-self: start; 
    font-weight: 600; 
`;

const CarrierContainer = styled.div`
   grid-area: 1 / 1 / 3 / 2;
`;


class Leg extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const legData = this.props.legData;

        return (
            <div>
                <Summery>
                    <LegContainer>
                        <CarrierContainer>
                            <Carrier details={legData.Carriers} iconOnly large/>
                        </CarrierContainer>
                        <DepartureTime>
                            <DateTime dateTime={legData.Departure} format={'LT'}/>
                        </DepartureTime>
                        <OriginStation>
                            <Station station={legData.OriginStation}/>
                        </OriginStation>
                        <LegInfographics>
                            <div>
                                <DurationContainer>
                                    <Duration duration={legData.Duration}/>
                                </DurationContainer>
                                <StopLine numStops={legData.NumStops}/>
                                <StopsContainer>
                                    <Stops numStops={legData.NumStops}/>
                                </StopsContainer>
                            </div>
                        </LegInfographics>
                        <ArrivalTime>
                            <TimeWithOffset
                                dateTime={legData.Arrival}
                                format={'LT'}
                                offsetFrom={legData.Departure}
                            />
                        </ArrivalTime>
                        <DestinationStation>
                            <Station station={legData.DestinationStation}/>
                        </DestinationStation>
                    </LegContainer>
                </Summery>
            </div>
        )
    }


}

export default Leg;