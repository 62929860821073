import React from 'react';
import _ from 'lodash';
import Tile from "./Tile";
import styled from 'styled-components';

const TilesContainer = styled.div`  
   text-align: center;
`;

class Tiles extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let tiles = this.props.tiles ? this.props.tiles : [];

        return (
            <TilesContainer>
                {tiles.map((value, index) => {
                    return (
                        <Tile
                            key={index}
                            click_url={value.click_url}
                            image_url={value.small_image_url}
                            ctaText={this.props.ctaText}
                            advertiser={value.buyer}
                            dealToken={index}
                            logoImageHeight={this.props.logoImagesHeight}
                            recordClickout={this.props.recordClickout}
                            sidePlacementEvent={this.props.sidePlacementEvent}
                        />
                    )
                })}
            </TilesContainer>

        )
    }
}
export default Tiles;

