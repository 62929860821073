import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

class SliderSkeleton extends React.Component {
    render() {
        return (

            <div style={{display: 'grid', gridTemplateColumns: '8% 86% 8%'}}>
                <Skeleton variant="circle" width={17} height={17} style={{alignSelf: 'center', justifySelf: 'right'}}/>
                <Skeleton height={4} width="100%"/>
                <Skeleton variant="circle" width={17} height={17} style={{alignSelf: 'center'}}/>
            </div>
        )

    }
}


export default SliderSkeleton;