import {SET_TRAVELERS} from "../constants";

export const setTravelers = (payload) => dispatch => {
    dispatch({
        type: SET_TRAVELERS, payload: {
            adults: payload.adults,
            children: payload.children
        }
    });
    return Promise.resolve()

};
