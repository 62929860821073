import {SET_RETURN_DATE} from "../constants";

export const setReturnDate = (returnDate) => dispatch => {

    dispatch({
        type: SET_RETURN_DATE, payload: {
            returnDate: returnDate
        }
    });
    return Promise.resolve()

};
