

import React from 'react';
import styled from 'styled-components';

const StationContainer = styled.div`  
`;

/*
            "DestinationStation": {
                Id: 9252,
                ParentId: 322,
                Code: "ADD",
                Type: "Airport",
                Name: "Addis Ababa"
            }
 */

class Station extends React.Component {
    constructor(props) {
        super(props)
    }

    getLabel = (station, fullDescription) => {
        return fullDescription ? `${station.Code} ${station.Name}` : `${station.Code}`;
    };

    render() {

        return (
            <StationContainer>{this.getLabel(this.props.station, this.props.full)}</StationContainer>
        )
    }


}

export default Station;