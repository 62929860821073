import React from 'react';
import styled from 'styled-components';

const NonStop = styled.span`  
    color: #00d775; 
`;

const Stop = styled.span`  
    color: #ff5452; 
`;

class Stops extends React.Component {
    constructor(props) {
        super(props)
    }

    getLabel = (stops) => {
        switch(stops){
            case 0: return <NonStop>Direct</NonStop>;
            case 1: return <Stop>1 Stop</Stop>;
            default: return <Stop>{`${stops} Stops`}</Stop>
        }
    };

    render() {
        return (
            this.getLabel(this.props.numStops)
        )
    }
}

export default Stops;