import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

const HorizontalBannerWrapper = styled.div`  
margin: 0 auto;
display: flex;
grid-auto-columns: 70% 30%;
font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue", Helvetica,Arial,"Lucida Grande",sans-serif;
font-weight: 400;
font-size: 1rem;
line-height: 1.5rem;
color: #524c61;
border: 1px solid #e5ebf0;
border-color: transparent;
box-shadow: 0 0 2px 0 rgba(19,26,31,0.12), 0 2px 4px 0 rgba(19,26,31,0.22);
background-color: white;
flex-direction: column;
// margin: 8px;
margin-bottom: 15px;
`;


const HorizontalBannerContainer = styled.a`  
    padding: 1em;
    padding-top: 1.3em;
    text-decoration: none;
    color: #524c61;
`;

const Headline = styled.div`  
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1em;
`;

const Description = styled.div`  
    font-size: 14px;
`;

const Url = styled.div`  
    font-size: 14px;
    color: #0072c6;
`;

const SecondSection = styled.div`  
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

`;

const Cta = styled.div`  
    justify-self: end; 
    color: #fff !important;
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #2196f3;
    padding: .375rem 1.125rem;
    border-radius: 5px;
    display: inline-block;
    border: none;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    background: #2196f3;
    border: 1px solid #2196f3;
    border-radius: 6px;


`;

class StyledHorizontalBanner extends React.Component {
    constructor(props) {
        super(props);
    }


    handleClick =  () => {
        this.props.sidePlacement({'position': -1, 'advertiser': this.props.display_url})
    }

    render() {
        return (
            <HorizontalBannerWrapper onClick={this.handleClick}>
                    <HorizontalBannerContainer href={this.props.click_url} target='_blank'>
                        <div>
                            <Headline>{this.props.headline}</Headline>
                            <Description>{this.props.description}</Description>
                            <Url>{this.props.display_url}</Url>
                        </div>
                        <SecondSection>
                            <div>
                                <img  src={this.props.image_url} />
                            </div>
                            <Cta>
                                {this.props.ctaText}
                            </Cta>
                        </SecondSection>
                    </HorizontalBannerContainer>
            </HorizontalBannerWrapper>
        )

    }

}
export default StyledHorizontalBanner;

