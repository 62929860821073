import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

const Logo = styled.img`
    height: 50px;
    width: 290px;
`;

const HeaderContent = styled.div`
    max-width: 1340px;
    // width: 80%;
    margin: 0 auto;
    padding: 0.4rem;
    text-align: center;     
`;

const useStyles = makeStyles(theme => ({
    appBar: {
      backgroundColor: '#fff',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    borderBottom: "1px #9e9e9e73 solid",
    boxShadow: "none",
    },
    toolBar: {
        minHeight : "68px"
    },
  }));

function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({target: window ? window() : undefined});

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}


export default function Header(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <HideOnScroll {...props}>
                <AppBar className={classes.appBar}>
                    <HeaderContent>
                        <Logo src={"/lastmin-flights-logo.png"}/>
                    </HeaderContent>
                </AppBar>
            </HideOnScroll>
            <Toolbar className={classes.toolBar}/>
        </React.Fragment>

    );
}