import React from 'react';
import {connect} from 'react-redux'
import _ from 'lodash'
import 'rc-slider/assets/index.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';
import {filterDuration} from "../actions/FilterDuration";

const mapStateToProps = (state) => {
    return {
        range: state.filterDetails.duration,
        value: !_.isEmpty(state.filterDetails.duration) ? state.filterDetails.duration.max : 0,
        rangeUpdated: state.filters.duration,
        valueUpdated: !_.isEmpty(state.filters.duration) ? state.filters.duration.max : 0,
    }
};


function mapDispatchToProps(dispatch) {
    return {
        filterDuration: (duration) => dispatch(filterDuration(duration))
    };
}

// Todo: extract the slider as a generic component - use it with containers
const SliderWithTooltip = createSliderWithTooltip(Slider);


class DurationFilter extends React.Component {

    constructor(props) {
        super(props);
        
        const value = this.props.range.max;
        this.state = {
            value: value,
            touched: false,
            prevValue: value
        }

    }

    componentDidMount() {
        if(this.props.rangeUpdated !== undefined){
            this.setState({
                value: this.props.rangeUpdated,
                prevValue: this.props.rangeUpdated
            })
        }
        else if(this.props.range !== undefined && this.props.range.max !== undefined){
            this.setState({
                value: this.props.range.max,
                prevValue: this.props.range.max
            })
        }
      }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.range, this.props.range) && !this.state.touched) {
            this.setState({
                value: this.props.range.max,
                prevValue: this.props.range.max
            })
        }
    }

    handleAfterChange(value) {
        if (value !== this.state.prevValue){
            this.setState({
                prevValue: value,
                touched: true
            });
            this.props.filterDuration(value);
        }
    }

    handleSliderChange(value) {
        this.setState({
            value: value
        })
    }

    formatDuration(duration) {
        let minutes = duration % 60;
        let hours = (duration - minutes) / 60;

        return hours + "h " + minutes + "m";
    }

    render() {

        if (!_.isEmpty(this.props.range)) {
            const minDuration = this.props.range.min;
            const maxDuration = this.props.range.max;

            return (
                <div>
                    <div>
                        <h4>Total Flight Duration</h4>
                        <p>{this.formatDuration(this.state.value)}</p>
                        <SliderWithTooltip
                            tipProps={{overlayClassName: 'foo'}}
                            min={minDuration}
                            max={maxDuration}
                            tipFormatter={value => this.formatDuration(value)}
                            value={this.state.value}
                            onChange={value => this.handleSliderChange(value)}
                            onAfterChange={(value) => this.handleAfterChange(value)}
                        />
                    </div>
                </div>
            )
        }

        return <div/>
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(DurationFilter);
