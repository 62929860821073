export const updateTimeRanges = (oldState, newState) =>
{
    const outboundDepartureTime = newState.outbound.departure;
    const outboundArrivalTime = newState.outbound.arrival;
    const inboundDepartureTime = newState.inbound.departure;
    const inboundArrivalTime = newState.inbound.arrival;

    oldState = updateTimeRange(oldState, outboundDepartureTime, "outbound", "departure");
    oldState = updateTimeRange(oldState, outboundArrivalTime, "outbound", "arrival");
    oldState = updateTimeRange(oldState, inboundDepartureTime, "inbound", "departure");
    oldState = updateTimeRange(oldState, inboundArrivalTime, "inbound", "arrival");

    return oldState;
};

export const updateTimeRange = (oldState, time, directionality, type) =>
{
    if (!oldState[directionality][type]["min"] || time["min"] < oldState[directionality][type]["min"]) {
        oldState[directionality][type]["min"] = time["min"];
    }
    if (!oldState[directionality][type]["max"] || time["max"] > oldState[directionality][type]["max"]) {
        oldState[directionality][type]["max"] = time["max"];
    }

    return oldState;
};