import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from "styled-components";
import moment from 'moment';
import FullScreenDialog from "../../Dialog/FullScreenDialog";
import _ from 'lodash';


const DateReangeInline = styled.div`
    z-index: 100;
    background: #fff;
    overflow-y: scroll;
`;

const DateRangeContainer = styled.div`
  position: relative; 
`;

const CustomInput = styled.input`
        padding: 42px 0 9px 15px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        width: 100%;
        // border-right: 1px #9e9e9e73 solid;
        border: 1px #9e9e9e73 solid;
        box-sizing: border-box;
        outline: none; 
`;


const DateRangeInputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
`;


class MobileDatePicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: moment().toDate(),
      openDialog: false,
      title: 'Depart Date'
    };
  }

  componentDidMount(){
    if (this.props.date !== undefined){
      this.setState({date: moment(this.props.date).toDate()})
    }
  }

  componentDidUpdate(prevProps){
    if (! _.isEqual(prevProps, this.props)){
      this.setState({
          date: moment(this.props.date).toDate()
      })
    }
  }

  handleDayClick = (date, modifiers = {}) => {

    if (modifiers.disabled) {
      return;
    }

    this.setState({
      date: date
    });
    this.props.setDepartDate(moment(date).format('YYYY-MM-DD'));
    //close:
    this.closeDialog();
  };

  openDialog = () => {
    this.setState({
      openDialog: true
    })
  };

  closeDialog = () => {
    this.setState({
      openDialog: false
    })
  };

  formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  
  render() {
    return (
      <div>
      <div className="InputFromTo">
      <div style={{position: 'relative'}}>
          <DateRangeContainer onMouseDown={this.openDialog}>
            <DateRangeInputLabel>{"Depart Date"}</DateRangeInputLabel>
            <CustomInput value={this.formatDate(this.state.date)} readOnly/>
          </DateRangeContainer>
      </div>
      <FullScreenDialog 
          title={this.state.title} 
          onClose={this.closeDialog} 
          open={this.state.openDialog} 
          isMobile={this.props.isMobile}
          style={{padding: 0, borderTop: "none",  borderBottom: "none"}}
      >
         <DateReangeInline>
          <DayPicker
            className="Selectable"
            selectedDays={[this.state.date]}
            disabledDays = {[{before: new Date()}]}
            onDayClick={this.handleDayClick}
            numberOfMonths={12}
            canChangeMonth={false}
          />
         </DateReangeInline>
      </FullScreenDialog>
        <Helmet>
          <style>{`
  .Selectable .DayPicker-wrapper {
    font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue", Helvetica,Arial,"Lucida Grande",sans-serif;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #2196f3;
  }

  .DayPicker-Caption > div {
    color: #0072c6;
  }

  .DayPicker-Day--today {
    color: #0072c6;
  }

  .Selectable .DayPicker-Month {
    width: 100%;
    margin: 0 2em;
    margin-top: 1em;
  }

  .Selectable .DayPicker-Day {
    outline: none;
  }
`}</style>
        </Helmet>
      </div>
      </div>
    );
  }
}


export default MobileDatePicker;