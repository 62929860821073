import React from 'react';
import queryString from 'query-string'
import {connect} from 'react-redux';
import _ from 'lodash';
import {fetchDeals} from "./actions/FetchDeals";
import {setQuery} from "./actions/SetQuery";
import FlightSearchForm from "./components/FlightsSearchForm";
import Results from "./components/Results";
import LinearProgress from "@material-ui/core/es/LinearProgress/LinearProgress";
import {setLoadingAction} from "./actions/SetLoadingAction";
import {setSearchInterval} from "./actions/SetSearchInterval";

import Header from "./components/Header/index";
import Summary from "./components/Summary/";

//MA
import {fetchComparisonData} from "./actions/FetchComparisonData";
import {validateSearchParams} from  "./actions/Validator";
import RefreshResults from './components/RefreshResults/RefreshResults';
import {recordLanding} from "./actions/recordLanding";
import Footer from './components/Footer';




class App extends React.Component {

    constructor(props) {
        super(props);
        this.props.recordLanding(this.props.location.search);
    }

    componentDidMount(){

        const values = queryString.parse(this.props.location.search);
       
        if(!("isMobile" in values)){
            values["isMobile"] = window.innerWidth <= 768;
        }
        if (!_.isEmpty(values)) {
            this.props.setQuery(values);
        }
        if (this.props.validateSearchParams(values,true)) {
            this.props.setLoadingAction(true);
            this.props.fetchDeals(values);
            this.props.setSearchInterval();
            this.props.fetchComparisonData(values, this.props.location.pathname);
        }
    }

    getProgressBarStyle = (isMobile) => {
        return isMobile ? {marginBottom: '0'} : {marginBottom: '2rem'};
    };

    render() {
        return (
            <div>
                <Header />
                <div style={{maxWidth: 1340, margin: '0 auto'}}>

                    {this.props.isMobile ?
                        <div>
                            {this.props.isLoading && <LinearProgress style={this.getProgressBarStyle(this.props.isMobile)}/>}
                            <Summary />
                        </div>
                        :
                        <div>
                            <FlightSearchForm/>
                            { this.props.isLoading && <LinearProgress style={this.getProgressBarStyle(this.props.isMobile)}/>}
                        </div>

                    }

                    {<Results/>}
                </div>
               <RefreshResults />
                <div>
                    <Footer />
                </div>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeals: (searchParameters) => dispatch(fetchDeals(searchParameters)),
        setQuery: (searchParameters) => dispatch(setQuery(searchParameters)),
        setLoadingAction: (value) => dispatch(setLoadingAction(value)),
        fetchComparisonData: (params, path) => dispatch(fetchComparisonData(params, path)),
        validateSearchParams: (params) => dispatch(validateSearchParams(params)),
        setSearchInterval: () => dispatch(setSearchInterval()),
        recordLanding: (urlParameters) => dispatch(recordLanding(urlParameters))
    };
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.status === false || state.isLoading,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
        isValid: state.isValid
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
