import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`  
    display: table;
    width: 100%;
`;

const CompareCta = styled.div`  
   color: #fff;
   background: #0072c6;
   padding: 1em;
   text-align: center;
   vertical-align: middle;
   display: table-cell;
`;

const LogoContainer = styled.div`  
   vertical-align: middle;
   display: table-cell;
   margin-left: 10px;

`;

const TileContainer = styled.div`  
    box-shadow: 0 1px 4px 0 hsla(0,0%,54%,.3);
    background: #fff;
    &:not(:first-child) {
        margin-top: 20px;
    }
`;

const TileImg = styled.img`  
    height: ${props => props.height|| "auto"};
    width: auto;
    vertical-align: middle;
`;

const ClickableWrapper = styled.a`  
    text-decoration: none;
`;

class Tile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <TileContainer>
                <ClickableWrapper
                    href={this.props.click_url}
                    target="_blank"
                    onClick={() => {
                        this.props.recordClickout({
                            ota: this.props.advertiser,
                            dealToken: this.props.dealToken,
                            advertiser: 'media_alpha'
                        })
                        this.props.sidePlacementEvent({
                            advertiser: this.props.advertiser,
                            position: this.props.dealToken
                        })
                    }}
                >
                    <ItemContainer>
                        <LogoContainer>
                            <TileImg src={this.props.image_url} height={this.props.logoImageHeight}/></LogoContainer>
                        <CompareCta>{this.props.ctaText}</CompareCta>
                    </ItemContainer>
                </ClickableWrapper>
            </TileContainer>

        )

    }

}
export default Tile;

