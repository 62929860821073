import React from 'react'
import {connect} from 'react-redux'
import DateRangePicker from "../DateRangePicker";
import {setDepartDate} from "../../../actions/SetDepartDate";
import {setReturnDate} from "../../../actions/SetReturnDate";
import MobileDateRangePicker from "../Mobile/MobileDateRangePicker";



const mapStateToProps = (state) => {
    return {
        to: (state.queryParams && state.queryParams.returnDate),
        from: (state.queryParams && state.queryParams.departDate),
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile))
    }
};


function mapDispatchToProps(dispatch) {
    return {
        fromHandler: value => dispatch(setDepartDate(value)),
        toHandler: value => dispatch(setReturnDate(value))
    }
}

function DateRangeContainer(props) { 

    if (props.isMobile) {
        return <MobileDateRangePicker  {...props} />
    }
    return <DateRangePicker  {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeContainer);
