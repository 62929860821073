import React from 'react';
import styled from 'styled-components';

const StopLineContainerList = styled.ul`
    position: relative;
    display: block;
    width: 70%;
    height: .125rem;
    margin: .375rem auto;
    padding: 0;
    border-radius: .375rem;
    background-color: #817b8f;
    line-height: 0;
    text-align: center;
`;

const StopLineDot = styled.li`
    position: relative;
    top: -.125rem;
    display: inline-block;
    width: .375rem;
    height: .375rem;
    margin: 0 4%;
    border-radius: .375rem;
    background-color: #ff5452;
    background-image: none;
    line-height: 0;
    box-shadow: 0 0 0 0.125rem #fff;
    zoom: 1;
`;

class StopLine extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const stops = [];
        for (let step = 0; step < this.props.numStops; step++) {
            stops.push(<StopLineDot key={step}/>)
        }

        return (
            (
                <StopLineContainerList>
                    {stops}
                </StopLineContainerList>
            )
        )
    }
}

export default StopLine;