import axios from 'axios';

export const fetchDestinations = (inputValue) => {
    return (dispatch) => {

        const queryParams = {
            type: 'airport',
            language: 'EN',
            term: inputValue,
        };

        return axios.get('https://lastmin-flights.com/response/json/entityAutocomplete.php', {
            params: queryParams,
        })
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw response;
                }
            })
            .then(json => {
                return json.data.results;
            })
            .catch(error => {
                throw error;
            });

    }
};