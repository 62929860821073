import {SET_TRIPTYPE} from "../constants";

export const setTripType = (oneWay) => dispatch => {

    dispatch({
        type: SET_TRIPTYPE, payload: {
            oneWay: Number(oneWay) || 0
        }
    });
    return Promise.resolve()

};
