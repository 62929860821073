import React, { Component } from 'react';
import Duration from "./Duration";
import styled from 'styled-components'
import {durationInMinutes} from "../utils/DateUtils";


const LayoverContainer = styled.div`  
    position: relative;
    display: flex;
    margin: .75rem -.75rem;
    padding: 0.75rem 0;
    color: #d1435b;
    border-radius: .375rem;
    background: #f1f2f8;

    @media only screen and (max-width: 768px) {
        padding: 0.4rem 0;
    }
`;

const DurrationContainer = styled.div`  
    display: flex;
    width: 4rem;
    min-width: 1.875rem;
    margin-left: .75rem;
    justify-content: center;
    align-items: center;
`; 


const ConnectionTextContainer = styled.div`  
    position: relative;
    display: inline-block;
    padding-left: 1.75rem;
`;


const ConnectionText = styled.span`  
    display: inline-block;
    padding-right: 1.125rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: normal;
`;


class Layover extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const getLayoverDuration = (a, b) => {
            let duration = durationInMinutes(a, b);
            return duration > 0 ? duration : "";
        };
    
        const getText = (a, b) => {
            return a !== b ? "Switch airport" : "Connect in airport";
        };

        const duration = getLayoverDuration(this.props.arrival, this.props.nextDepartureTime);
        const text = getText(this.props.destinationStationCode, this.props.nextStationCode);

        return (
            <LayoverContainer>
                <DurrationContainer>
                    <Duration duration={duration}/>
                </DurrationContainer>
                <ConnectionTextContainer>
                    <ConnectionText>{text}</ConnectionText>
                </ConnectionTextContainer>
            </LayoverContainer>
        )
    }
}

export default Layover;