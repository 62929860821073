import React from 'react';
import styled from 'styled-components'
import MultiChoiceSkeleton from './MultiChoice';
import SliderSkeleton from './Slider';

const FiltersContainer = styled.div`
    padding: 1rem; 
    margin-bottom: 15px; 
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400; 
    font-size: 0.9rem;
    line-height: 1.4; 
    color: #524c61; 
    border: 1px solid #e5ebf0;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    background-color: white;

    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

class FiltersSkeleton extends React.Component {

    render() {
        return (
            <FiltersContainer style={{width: 'auto', height: "fit-content"}}>
                <MultiChoiceSkeleton copies={3} choicesLabel={'Stops'}/>
                <h4>Outbound Takeoff Time</h4>
                <SliderSkeleton/>
                <h4>Inbound Takeoff Time</h4>
                <SliderSkeleton/>
                <h4>Outbound Landing Time</h4>
                <SliderSkeleton/>
                <h4>Inbound Landing Time</h4>
                <SliderSkeleton/>
                <MultiChoiceSkeleton copies={10} choicesLabel={'Airlines'}/>
                <h4>Total Flight Duration</h4>
                <SliderSkeleton/>
            </FiltersContainer>
        )
    }
}

export default FiltersSkeleton;