import axios from "axios";
import {sessionTokenUpdate} from "./FetchDeals";



export const recordClickout = (dealInfo) => {

    return (dispatch, getState) => {
        const queryParams = {
            ota: dealInfo.ota,
            price: dealInfo.price,
            currency: dealInfo.currency,
            document_path: dealInfo.documentPath,
            deal_token: dealInfo.dealToken,
            _token: getState().token,
            advertiser: dealInfo.advertiser
        };

        return axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/record/clickout', {
            params: {...queryParams}
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(sessionTokenUpdate(response.headers._token));
                    return response.data;
                } else {
                    throw response.message;
                }
            })
            .catch(error => {
                throw error;
            });
    };

};


