import React from 'react';
import styled from 'styled-components'
import Segment from "./Segment";
import DateTime from "./DateTime";
import Layover from "./Layover";


const ConnectionDetails = styled.div`
    margin: .75rem -.75rem;
`;

const SegmentsContainer = styled.div`
    background: white; 
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
    padding: 1rem;   
`;

const LegHeader = styled.div`
    display: flex;
    margin: 0.375rem 0 0.375rem 0; 
`;

class LegDetails extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const renderLayover = (value, index, segments) => {
            if(index < segments.length-1){
                let next = segments[index+1];
                return <Layover 
                    arrival= {value.ArrivalDateTime}
                    nextDepartureTime = {next.DepartureDateTime}
                    destinationStationCode={value.DestinationStation.Code}
                    nextStationCode={next.OriginStation.Code}
                />
            }
        };

        return (
            <div>
                <LegHeader>
                    <h4 style={{margin: 0}}>{this.props.legData.Directionality}</h4>
                    {'\u00A0'}
                    <DateTime dateTime={this.props.legData.Departure} format={'dddd, MMMM Do, YYYY'}/>
                </LegHeader>
                <SegmentsContainer>
                    {this.props.segments.map((value, index) => {
                        return (
                            <div style={{marginBottom: `1rem`}}  key={value.Id}>
                                <Segment
                                    segment={value}
                                />
                                {renderLayover(value, index, this.props.segments)}
                            </div>
                        )
                    })}
                </SegmentsContainer>
            </div>
        )
    }


}

export default LegDetails;