import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CustomButton from './CustomButton';


const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#2196f3',
    fontFamily: ["HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue","Helvetica","Arial","Lucida Grande","sans-serif"].join(','),
    color: '#fff',
    borderRadius: '0',
    textTransform: "capitalize",
    width: "100%",
    marginTop: "1.5rem",
    fontSize: "1rem",

    '&:hover': {
      backgroundColor: '#2196f3',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2196f3',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}));

export default function SearchButton(props) {
  const classes = useStyles();

  return (
    <CustomButton classes={classes} {...props}></CustomButton>
  );
}