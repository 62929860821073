import axios from 'axios';
import {TRAVEL_EXCHANGE_COMPARISON_UPDATED} from "../constants";
import moment from "moment";

export const comparisonListUpdated = (payload, actionType) => {
    return {
        type: TRAVEL_EXCHANGE_COMPARISON_UPDATED,
        payload: payload
    };
};

export const fetchComparisonData = (searchParameters, referrerUrl) => {

    return (dispatch) => {

        return axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/compare/flights', {
            params: {
                local_hour : moment().format("HH"),
                locale: "en",
                origin: searchParameters.origin,
                destination: searchParameters.destination,
                depart_date: searchParameters.departDate,
                return_date: searchParameters.returnDate,
                cabin: searchParameters.cabin.toLowerCase(),
                adults: searchParameters.adults,
                children: searchParameters.children,
                referrer_url: process.env.REACT_APP_DOMAIN + referrerUrl,
                one_way: Number(searchParameters.oneWay) || 0
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw response.message;
                }
            })
            .then(json => {
                const jsonResponse = json.data;
                dispatch(comparisonListUpdated(jsonResponse));

            })
            .catch(error => {
                throw error;
            });

    }
};