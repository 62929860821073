import React from 'react';
import Button from '@material-ui/core/Button';


export default function CustomButton(props) {
  const classes = props.classes;
  const icon = props.icon? props.icon : <span></span>;

  return (
    <div>
         <Button 
            variant="contained" 
            className={classes.button} 
            onClick={props.onClick} 
            endIcon={icon}
         >
         {props.buttonName} 
        </Button>
    </div>
  );
}