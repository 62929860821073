import _ from 'lodash';

export const customizer = (a, b) => {
    if (typeof a !== undefined && typeof b !== undefined && a && b){
        if (Number(a.replace(/\D/, '').replace(',', '')) < Number(b.replace(/\D/, '').replace(',', ''))) {
            return a;
        } else {
            return b;
        }
    } else if (typeof a !== undefined && a) {
        return a;
    }
    return b;
};

export const carrierCustomizer = (a, b) => {
    if (typeof a !== undefined && typeof b !== undefined && a && b){
        return Math.min(a.price,b.price) === b.price ? b : a ;
    } else if (typeof a !== undefined && a) {
        return a;
    }
    return b;
};
