import React from 'react';
import moment from 'moment'
import _ from 'lodash';
import 'rc-slider/assets/index.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';
import {diffInDays} from "../../utils/DateUtils";
import DateTime from "../DateTime";
import {wrapMapToPropsConstant} from "react-redux/lib/connect/wrapMapToProps";

const DateTimeStyle = {
    display: 'inline-block'
};

const RangeWithTooltip = createSliderWithTooltip(Slider.Range);

class Range extends React.Component {

    constructor(props) {
        super(props);

        const range = this.props.range;
        this.state = {
            range: range,
            touched: false,
            prevRange: range
        }
    }

    componentDidMount() {

      if(this.props.rangeValue !== undefined){
        this.setState({
            range: this.props.rangeValue,
            prevRange: this.props.rangeValuee
        })
      }
      else if(this.props.range !== undefined){
        this.setState({
            range: this.props.range,
            prevRange: this.props.range
        })
      }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.range, this.props.range) && !this.state.touched) {
            this.setState({
                range: this.props.range,
                prevRange: this.props.range
            })
        }
    }

    handleAfterChange(value) {
        if (!_.isEqual(value, this.state.prevRange)) {
            this.setState({
                prevRange: value,
                touched: true
            });
            this.props.setValue(value);
        }
        this.props.filterEvent({
            filterName: this.props.name,
            filterAction: 'slider'/*value*/
        })
    }

    onSliderChange = (value) => {

        this.setState({
            range: value,
        })
    };

    formatDateTime = (time) => {
        return moment(time).format("lll");
    };

    getMaxDateFormat = (offsetFrom, dateTime) => {
        return diffInDays(offsetFrom, dateTime) !== 0 ? "ddd hh:mm a" : "hh:mm a";
    };

    render() {

        if (!_.isEmpty(this.props.range)) {

            const min = Number(moment(this.props.min).valueOf());
            const max = Number(moment(this.props.max).valueOf());
            const maxDateFormat = this.getMaxDateFormat(this.state.range[0], this.state.range[1]);

            return (
                <div>
                    <div>
                        <h4>{this.props.title}</h4>
                        <div>
                            <DateTime dateTime={this.state.range[0]} format="ddd hh:mm a" style={DateTimeStyle}/>
                            {`${' - '}`}
                            <DateTime dateTime={this.state.range[1]} format={maxDateFormat} style={DateTimeStyle}/>
                        </div>

                        <RangeWithTooltip
                            min={min}
                            max={max}
                            allowCross={false}
                            name={this.props.name}
                            value={this.state.range}
                            tipFormatter={value => this.formatDateTime(value)}
                            onAfterChange={(value) => this.handleAfterChange(value)}
                            onChange={(value) => this.onSliderChange(value)}
                            step={this.props.step}
                        />
                    </div>
                </div>
            )
        }

        return <div/>
    }

}

export default Range;
