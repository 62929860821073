import Cookies from 'universal-cookie';



export const setLastSearch = (searchParameters) => {


    const cookies = new Cookies();

    cookies.set('last_search', JSON.stringify(searchParameters), {
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        maxAge: 60 * 60 * 24 * 30
    });

};