import React from 'react'
import {connect} from 'react-redux'
import {setDepartDate} from "../../../actions/SetDepartDate";
import DatePicker from "../DatePicker";
import MobileDatePicker from "../Mobile/MobileDatePicker";
import {setReturnDate} from "../../../actions/SetReturnDate";



const mapStateToProps = (state) => {
    return {
        date: (state.queryParams && state.queryParams.departDate),
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile))
    }
};


function mapDispatchToProps(dispatch) {
    return {
        setDepartDate: value => dispatch(setDepartDate(value)),
        setReturnDate: value => dispatch(setReturnDate(value))
    }
}

function DatePickerContainer(props) { 

    if (props.isMobile) {
        return <MobileDatePicker  {...props} />
    }
    return <DatePicker  {...props} />

}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer);
