import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux'
import Deal from "../components/Deal";
import {fetchDeals} from "../actions/FetchDeals";
import DealSkeleton from "./Skeletons/Deal";
import styled from 'styled-components';
import HorizontalBanner from './HorizontalBanner/Container'
import NoResults from "./Results/NoResults";

import {Virtuoso} from 'react-virtuoso';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const DealsContainer = styled.div`
    @media only screen and (max-width: 768px){
        margin: 0px;
    }
`;


const mapStateToProps = state => {
    return {
        deals: state.deals,
        status: state.status,
        done: state.done,
        page: state.page,
        filters: state.filters,
        isLoading: state.isLoading,
        queryParams: state.queryParams,
        stops: state.filters.stops,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
        bannerDataList: state.travelExchangeComparisonList
    }
};

function mapDispatchToProps(dispatch) {
    return {
        fetchDeals: (searchParameters) => dispatch(fetchDeals(searchParameters)),
    };
}

const ListContainer = ({listRef, style, children}) => {
    return (
        <List ref={listRef} style={{...style, padding: 0}}>
            {children}
        </List>
    );
};

const ItemContainer = ({children, ...props}) => {
    const listStyle = {margin: 0};
    return (
        <ListItem {...props} style={listStyle}>
            {children}
        </ListItem>
    );
};

const ItemContainerMobile = ({children, ...props}) => {
    const listStyle = {margin: 0, paddingLeft: '5px',  paddingRight: '5px'};
    return (
        <ListItem {...props} style={listStyle}>
            {children}
        </ListItem>
    );
};

class DealsList extends React.Component {

    state = {
        numOfItems: 20,
        endReached : false,
        loadedDeals: [],
    };

    componentDidUpdate(prevProps) {

        if (_.isEqual(prevProps.filters, this.props.filters)) {
            if (this.props.status != null && !this.props.status) {
                this.props.fetchDeals(this.props.queryParams);
            } else {
                if (this.props.done != null && !this.props.done) {
                    this.props.fetchDeals(this.props.queryParams);
                }
            }
        }
    }


    renderDeals(deals) {
        const TOTAL_COUNT = deals.length;

        let loadedDeals = deals;

        if(loadedDeals.length > 0 && this.props.bannerDataList !== undefined && this.props.bannerDataList.length > 0 && this.props.isMobile){
            let j=1;
            for(let i=0; i<this.props.bannerDataList.length; i++){
                loadedDeals.splice(j, 0, {type: 'banner', index: i});
                j= j+4;
            }

        }

        const itemContainer =  this.props.isMobile ? ItemContainerMobile : ItemContainer;

        const noDeals = this.props.status != null && this.props.status && this.props.done != null && this.props.done && this.props.deals && this.props.deals.length === 0;


        if(noDeals){
            return <NoResults />
        }

        return <Virtuoso
            ListContainer={ListContainer}
            ItemContainer={itemContainer}
            style={{width: '100%', height: '70vh'}}
            totalCount={loadedDeals.length}
            footer={() => {
                return (
                    <div>
                        {loadedDeals.length === TOTAL_COUNT ? <div/> : ' loading...'}
                    </div>
                );
            }}
            overscan={200}
            item={index => {
                return (
                    <ListItemText>
                        {loadedDeals[index].type !== undefined && this.props.isMobile
                            ? <HorizontalBanner index={loadedDeals[index].index}/>
                            : <div key={`${loadedDeals[index].OutboundLegId}${loadedDeals[index].InboundLegId}`}>
                                <Deal info={loadedDeals[index]} travelers={this.props.queryParams.adults + this.props.queryParams.children}/>
                            </div>
                        }

                    </ListItemText>
                );
            }}
        />
    }

    render() {

        let deals = this.props.deals ? this.props.deals : [];

        if (this.props.filters.stops !== undefined) {
            deals = deals.filter(
                deal => {
                    const filteredStops = this.props.filters.stops;
                    const outboundLegStops = !_.isEmpty(deal.OutboundLeg) ? deal.OutboundLeg.NumStops : 0;
                    const inboundLegStops  = !_.isEmpty(deal.InboundLeg) ? deal.InboundLeg.NumStops : 0;

                    return filteredStops.includes(_.max([inboundLegStops, outboundLegStops]));
                }
            );
        }

        if (this.props.filters.outboundDepartureTimeRange !== undefined) {
            deals = deals.filter(
                deal =>
                    deal.OutboundLeg.Departure >= this.props.filters.outboundDepartureTimeRange[0]
                    && deal.OutboundLeg.Departure <= this.props.filters.outboundDepartureTimeRange[1]
            );
        }


        if (this.props.filters.inboundDepartureTimeRange !== undefined) {
            deals = deals.filter(
                deal => !_.isEmpty(deal.OutboundLeg)
                    && deal.InboundLeg.Departure >= this.props.filters.inboundDepartureTimeRange[0]
                    && deal.InboundLeg.Departure <= this.props.filters.inboundDepartureTimeRange[1]
            );
        }

        if (this.props.filters.outboundArrivalTimeRange !== undefined) {
            deals = deals.filter(
                deal => deal.OutboundLeg.Arrival >= this.props.filters.outboundArrivalTimeRange[0]
                    && deal.OutboundLeg.Arrival <= this.props.filters.outboundArrivalTimeRange[1]
            );
        }

        if (this.props.filters.inboundArrivalTimeRange !== undefined) {
            deals = deals.filter(
                deal => !_.isEmpty(deal.InboundLeg)
                    && deal.InboundLeg.Arrival >= this.props.filters.inboundArrivalTimeRange[0]
                    && deal.InboundLeg.Arrival <= this.props.filters.inboundArrivalTimeRange[1]
            );
        }

        if (this.props.filters.duration !== undefined) {
            deals = deals.filter(
                deal => !_.isEmpty(deal.InboundLeg)
                    && ((Number(deal.OutboundLeg.Duration) + Number(deal.InboundLeg.Duration)) <= this.props.filters.duration)
            );
        }

        if (this.props.filters.carriers !== undefined) {
            deals = deals.filter((deal) => {
                    const dealCarriers = !_.isEmpty(deal.InboundLeg)
                        ? [...deal.OutboundLeg.Carriers, ...deal.InboundLeg.Carriers]
                        : deal.OutboundLeg.Carriers;

                    let carrierCodes = _.uniq(dealCarriers.map(carrier => carrier.Code));

                    if (carrierCodes.length > 1) {
                        carrierCodes = ["multiple"];
                    }

                    return _.isEmpty(_.intersection(this.props.filters.carriers, carrierCodes));
                }
            );
        }

        if (this.props.isLoading) {
            return (
                <DealsContainer>
                    {_.times(20, (i) => {
                        return <DealSkeleton key={i}/>
                    })}
                </DealsContainer>
            )
        }
        return (
            <div>
                <DealsContainer>
                    {this.renderDeals(deals)}
                </DealsContainer>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DealsList);