import React from 'react';
import {connect} from 'react-redux'

import TimeoutAlert from "../Dialog/TimeoutAlert";


class Validation extends React.Component {

    state = {
        error: {
            errorMsg: '',
            errorTitle: '',
        },
    };


    closeErrorAlert= () => {
        if(this.props.closeError){
            this.props.closeError();
        }
    };


    getError = () => {
        for(let field in this.props.invalidFields){
            if(!this.props.invalidFields[field].isValid){
                return {errorMsg: this.props.invalidFields[field].msg, errorTitle: 'Error'};
            }
        }
        return {errorMsg: '', errorTitle: ''}
    };

    render() {
        const error = this.getError();
        return <TimeoutAlert
            title={error.errorTitle}
            msg={error.errorMsg}
            onClose={this.closeErrorAlert}
            open={this.props.showError}
        />
    }
}

const mapStateToProps = state => {
    return {
        isValid: state.isValid,
        invalidFields: state.invalidFields,
        queryParams: state.queryParams,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile))
    }
};

export default connect(mapStateToProps, null)(Validation);

