import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux'
import Banner from "../Banner";
import {recordClickout} from "../../../actions/recordClickout";
import {sidePlacementEvent} from "../../../actions/DataLayers";



const mapStateToProps = state => {
    return {
        tiles: state.travelExchangeComparisonList,
        searchParams: { ...state.queryParams, stops: state.stops},
        done: state.done,
        deals: state.deals,
        isLoading: state.isLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        recordClickout: (dealInfo) => dispatch(recordClickout(dealInfo)),
        sidePlacementEvent: (dealInfo)  => dispatch(sidePlacementEvent(dealInfo))
    };
};

class BannerContainer extends React.Component {

    render() {

        if (_.isEmpty(this.props.deals)) {
            return <div/>
        }


        return (
            <Banner
                title={this.props.content.title}
                promotionTextMsg={this.props.content.promotionTextMsg}
                tiles={this.props.tiles}
                ctaText={this.props.content.ctaText}
                logoImagesHeight={this.props.content.logoImagesHeight}
                recordClickout={this.props.recordClickout}
                sidePlacementEvent={this.props.sidePlacementEvent}
            />
        )

    }

}
export default connect(mapStateToProps, mapDispatchToProps)(BannerContainer);

