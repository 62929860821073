import React from 'react';
import BannerContainer from "./BannerContainer";


class Banners extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
              <BannerContainer content={{
                  title: "Compare and Save",
                  promotionTextMsg: "Our users saved an extra 8% on average when searching multiple travel sites compared to just one.",
                  ctaText: "Compare",
                  logoImagesHeight: "52px"
              }}/>
            </div>
            
        )

    }
}
export default Banners;

