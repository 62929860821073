import React from 'react';

import styled from 'styled-components'

const OptionContainer = styled.div`
    display: flex; 
`;

const AgentName = styled.div`
`;

const OptionPrice = styled.div`
`;

class BookingOption extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a href={this.props.url}
               style={{textDecoration: 'none', color:'inherit'}}
               rel="noopener noreferrer"
               onClick={this.props.onClick}
               target={"_blank"}
            >
                <OptionContainer>
                    <AgentName>
                        {this.props.agentName}
                    </AgentName>
                    {'\u00A0'}
                    <OptionPrice>
                        {this.props.displayPrice}
                    </OptionPrice>
                </OptionContainer>
            </a>
        )


    }
}

export default BookingOption;