import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from "./store/index";
import { createStore } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import * as TagManager from "react-gtm-module";
import Terms from './components/Footer/Terms';
import Privacy from './components/Footer/Privacy';

TagManager.initialize({
    gtmId: 'GTM-WTVT4BC'
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/terms" component={Terms} exact/>
                <Route path="/privacy" component={Privacy} exact/>
                <Route path="/" component={App} />
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
