import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {formatDate, parseDate} from "react-day-picker/moment";
import styled from 'styled-components'
import moment from "moment/moment";
import _ from 'lodash'

const datePickerInputStyles = {
    padding: '42px 0 9px 15px',
    fontSize: '18px',
    fontWeight: '500',
    border: 'none',
    width: '100%',
    borderRight: '1px #9e9e9e73 solid',
    boxSizing: 'border-box',
    outline: 'none'
};

const DatePickerContainer = styled.div`
`;

const DatePickerLabelAnchor = styled.div`
    position: relative
`;

const DatePickerInputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
`;

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.props.setReturnDate(null)
    }

    // componentDidMount(){
    //     if (this.props.date !== undefined){
    //       this.setState({date: moment(this.props.date).toDate()})
    //     }
    // }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.setDates();
        }
    }

    handleDayChange = (day) => {
        this.props.setDepartDate(moment(day).format('YYYY-MM-DD'));
    };

    setDates() {

        let date = this.props.date !== undefined ? moment(this.props.date) : undefined;

        if (date === undefined) {
            return;
        }

       this.props.setDepartDate(moment(date).format('YYYY-MM-DD'));
    }


    render() {
        let date = moment(this.props.date).toDate();

        return (
            <DatePickerContainer>
                <DatePickerLabelAnchor>
                    <DatePickerInputLabel>{this.props.label}</DatePickerInputLabel>
                    <DayPickerInput
                        style={{width: '100%'}}
                        inputProps={{readOnly: true, style: datePickerInputStyles}}
                        value={date}
                        placeholder="-"
                        keepFocus={false}
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [date],
                            disabledDays: [{before: new Date()}],
                            month: new Date(),
                            numberOfMonths: 1,
                        }}
                        onDayChange={day => this.handleDayChange(day)}/>
                </DatePickerLabelAnchor>
            </DatePickerContainer>
        );
    }

}

export default DatePicker;