import React from 'react';
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton';


const FilterRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Reset = styled.h4`
    cursor: pointer;
    color: #2196f3;
`;

class MultiChoiceSkeleton extends React.Component {

    elementloop = (copies) => {
        const objs = [];
        for (let i = 0; i < copies; i++) {
            objs.push(
                <div style={{display: 'grid', gridTemplateColumns: '10% 25% 40% 25%', marginBottom: '-11px'}} key={i}>
                    <Skeleton variant="rect" width={20} height={20} style={{alignSelf: 'center'}}/>
                    <Skeleton variant="text" height={20} width="98%" style={{alignSelf: 'center', justifySelf: 'right'}}/>
                    <span/>
                    <Skeleton variant="text" height={20} width="98%" style={{alignSelf: 'center'}}/>
                </div>
            )
        }
        return objs;
    };

    render() {

        return (
            <div>
                <FilterRowContainer>
                    <h4>{this.props.choicesLabel}</h4>
                    <Reset>Reset</Reset>
                </FilterRowContainer>
                {this.elementloop(this.props.copies)}
            </div>
        )
    }
}

export default MultiChoiceSkeleton;