import React  from 'react';
import styled from 'styled-components'

import {diffInDays} from "../utils/DateUtils";

class Offset extends React.Component {
    constructor(props) {
        super(props)
    }

    isFormatValid = (format) => {
        const validFormats = ['LT'];
        return validFormats.indexOf(format) > -1;
    };

    isOffsetValid(format, offset) {
        return this.isFormatValid(format) && offset && offset !== 0
    }

    getOffsetLabel = (offset) => {
        const operator = offset > 0 ? "+" : "";

        return `${operator}${offset}`;
    };


    render() {
        const {dateTime, offsetFrom, format} = this.props;
        const offset = diffInDays(offsetFrom, dateTime);

        if (this.isOffsetValid(format, offset)) {
            const offsetLabel = this.getOffsetLabel(offset);
            const WrapperComponent = this.props.wrapperComponent;

            return WrapperComponent ? <WrapperComponent offset={offsetLabel}/> : offsetLabel;
        }

        return false;

    }
}

export default Offset;

/*use :
<Offset wrapperComponent={component} dateTime={} offsetFrom={} format={}> */

