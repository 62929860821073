import {SET_QUERY_PARAMS} from "../constants/index";

export function setQueryParams(payload) {
    return {type: SET_QUERY_PARAMS, payload}
}

export const setQuery = (searchParameters) => dispatch => {

    /*const origin      = searchParameters.origin;
    const destination = searchParameters.destination;
    const departDate  = searchParameters.departDate;
    const returnDate  = searchParameters.returnDate;
    const travelers   = searchParameers.travelers;
    //const tripType  = searchParameters.tripType;
    //const quoteKey = btoa(`${origin}${destination}${departDate}${returnDate}${travelers}`); // base64 encoding of user search parameters.*/

    dispatch(setQueryParams({query: searchParameters}));

    return Promise.resolve();

};