import React from 'react';
import {connect} from 'react-redux'
import moment from 'moment'
import _ from 'lodash';
import {setTimeRangeFilter} from "../../../actions/FilterTimes";
import {filterEvent} from "../../../actions/DataLayers";
import Range from "../Range"
import {FILTER_OUTBOUND_ARRIVAL_TIMES} from "../../../constants";

const mapStateToProps = (state, ownProps) => {

    const min = !_.isEmpty(state.filterDetails.times) ? Number(moment(state.filterDetails.times.outbound.arrival.min).valueOf()) : 0;
    const max = !_.isEmpty(state.filterDetails.times) ? Number(moment(state.filterDetails.times.outbound.arrival.max).valueOf()) : 0;
    const range = min && max ? [min, max] : [];

    return {
        min: min,
        max: max,
        range: range,
        name: ownProps.name,
        title: ownProps.title,
        rangeValue: state.filters.outboundArrivalTimeRangeValue
    }
};


function mapDispatchToProps(dispatch) {
    return {
        setValue: (value) => dispatch(setTimeRangeFilter(FILTER_OUTBOUND_ARRIVAL_TIMES, value)),
        filterEvent: (value) => dispatch(filterEvent(value))
    };
}

const OutboundArrivalFilter = connect(mapStateToProps, mapDispatchToProps)(Range);

export default OutboundArrivalFilter;