import {SET_ORIGIN} from "../constants";

export const setOrigin = (origin,originCityName,originStateName,originCountryName,originCountryFipsCode) => dispatch => {
    dispatch({
        type: SET_ORIGIN, payload: {
            origin: origin,
            originCityName: originCityName,
            originStateName: originStateName,
            originCountryName: originCountryName,
            originCountryFipsCode: originCountryFipsCode
        }
    });
    return Promise.resolve()

};
