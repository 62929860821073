import moment from 'moment'

export const durationInMinutes  = (a, b) => {

    if (typeof a !== undefined && typeof b !== undefined && a && b){
        const momentA = moment(a);
        const momentB = moment(b);
        const duration = moment.duration(momentB.diff(momentA));
        return duration.asMinutes();
    }
};

export const diffInDays = (a, b) => {

    if (typeof a !== undefined && typeof b !== undefined && a && b){
        const subtrahend = moment(a).startOf('day');
        const minuend = moment(b).startOf('day');
        return minuend.diff(subtrahend, 'days');
    }
    return 0;
};

export const formatDuration = (duration) => {

    let minutes = duration % 60;
    let hours = (duration - minutes) / 60;
    return hours + "h " + minutes + "m";
 };
