import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const StyledRadio = withStyles({
  root: {
    '&$checked': {
      color: '#2196f3',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

export default function CustomRadio(props) {

  return (
      <StyledRadio
        checked={props.checked}
        value= {props.value}
      />
  );
}