import React from 'react';


const NoResults = () => {
    return (
        <div className="noresults">
            <h2>
            No matches found
            </h2>
            Please try another search
        </div>
    )
}


export default NoResults;