import React from 'react';
import styled from 'styled-components'
import TravelerCounter from './TravelerCounter'
import {Carousel} from "../Carousel";


const TravelersContainer = styled.div`
    margin: 0 auto; 
    padding-left: 1rem; 
    padding-right: 1rem; 
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400; 
    font-size: 1rem;
    line-height: 1.5rem; 
    color: #524c61;
    border: 1px solid #e5ebf0;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    background-color: white;
    position: absolute; 
    z-index: 100;

    @media only screen and (max-width: 768px){
        background-color: #fff;
        width: 100%;
        padding-left: 1em; 
        padding-right: 1em; 
        left: 0;
        right: 0; 
        box-shadow: none;
    }
`;

const DoneContainer = styled.div`
    border-top: 1px #80808038 solid;
    text-align: center;
    padding: 1rem; 
`;

const Done = styled.a`
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: black;
`;

class Travelers extends React.Component {

    renderDone = () => {
        if (this.props.isMobile) {
            return <div/>
        }

        return (
            <DoneContainer>
                <Done href={"#"} onClick={this.props.close}>Done</Done>
            </DoneContainer>
        )
    };

    render() {
        return (
            <TravelersContainer> 
                <Carousel options={['Economy', 'Premium', 'First', 'Business']}
                          onChange={this.props.onCarouselChange}
                          style={{borderBottom: '1px #80808038 solid'}}
                          cabin={this.props.cabin}
                />
                <TravelerCounter
                    type={'adults'}
                    description={'Age 16+ at the time of flight'}
                    title={'Adults'}
                    count={this.props.adults}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                />

                <TravelerCounter
                    type={'children'}
                    description={'Age 1-15 years at time of flight'}
                    title={'Children'}
                    count={this.props.children}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                />

                {this.renderDone()}
            </TravelersContainer>
        )
    }


}

export default Travelers;