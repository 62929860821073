import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux'
import {addStop, removeStop, resetStops} from "../actions/FilterStops";
import {filterEvent} from "../actions/DataLayers";
import styled from "styled-components";

const FilterRowContainer = styled.div`
    display: flex;
    justify-content: space-between; 
`;

const Reset = styled.h4`
    cursor: pointer;
    color: #2196f3;
`;

const mapStateToProps = (state) => {
    return {
        currentStops: state.filters.stops,
        options: state.filterDetails.stops
    }
};


function mapDispatchToProps(dispatch) {
    return {
        addStop: (stops) => dispatch(addStop(stops)),
        removeStop: (stops) => dispatch(removeStop(stops)),
        reset: () => dispatch(resetStops()),
        filterEvent: (metaData) => dispatch(filterEvent(metaData))
    };
}

class StopsFilter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stops: {
                0: {label: "Non-Stop", checked: true},
                1: {label: "1 Stop", checked: true},
                2: {label: "2+ Stops", checked: true}
            }
        }

    }

    componentDidMount() {
        this.updateStops(this.state.stops, this.props.currentStops)
    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps, this.props)) {
            this.updateStops(this.state.stops, this.props.currentStops)
        }
    }

    updateStops = (stops, currentStops) => {

        if(currentStops !== undefined){
            this.props.currentStops.forEach(element => {
                if(element in stops){
                    stops[element].checked = true;
                }
            });
            this.setState({stops: stops});
        }
    };


    handleInputChange = event => {
        const stop = Number(event.target.value);
        const isChecked = event.target.checked;

        let tempState = {...this.state};
        tempState.stops[stop].checked = isChecked;
        this.setState({currentState: tempState});

        if (isChecked) {
            this.props.addStop(stop);
        } else {
            this.props.removeStop(stop);
        }

        this.props.filterEvent({
            filter_name: 'stops',
            filterAction: 'clicked stop'/*this.state.stops*/
        })
    };

    handleReset = () => {
        let tempState = {...this.state};
        _.values(tempState.stops).map(stop => stop.checked = true);
        this.setState({currentState: tempState});
        this.props.reset();
    };

    render() {

        const options = this.props.options;
        const stops = _.keys(options);


        if (_.isEmpty(options)) {
            return <div/>
        }

        return (
            <div>
                <div>
                    <FilterRowContainer>
                        <h4>Stops</h4>
                        <Reset onClick={this.handleReset}>Reset</Reset>
                    </FilterRowContainer>
                    {stops.map((stop) => {
                        return (
                            <FilterRowContainer key={stop}>
                                <div >
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={stop}
                                            checked={this.state.stops[stop].checked}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                        {`${this.state.stops[stop].label}`}
                                    </label>
                                </div>
                                <div style={{display: 'flex'}}>
                                    {/*<div onClick={() => this.handleClick(stop)}>Only</div>*/}
                                    {/*{`\u00A0`}*/}
                                    <div>{options[stop]}</div>
                                </div>
                            </FilterRowContainer>)
                    })}
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(StopsFilter);

