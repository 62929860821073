import {SET_LOADING_ACTION} from "../constants";

export const setLoadingAction = (isLoading) => dispatch => {

    dispatch({
        type: SET_LOADING_ACTION,
        payload: {
            isLoading: isLoading
        }
    });
    return Promise.resolve()

};
