
export const validateSearchParams = (params) =>{

    return () => {

        return ( params
            && params.origin && /[A-Z]{3}/.test(params.origin)
            && params.destination && /[A-Z]{3}/.test(params.destination) && params.origin !== params.destination
            && params.adults > 0 && params.children > -1
            && params.cabin && ['Economy', 'First', 'Business', 'Premium'].includes(params.cabin)
            && params.departDate && /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(params.departDate)
            && (params.oneWay || (
                params.returnDate && /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(params.returnDate))
            )
            && (params.oneWay || new Date(params.departDate) < new Date(params.returnDate))
        );

    };
};