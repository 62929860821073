import {ADD_STOP, REMOVE_STOP, RESET_STOPS} from "../constants/index";



// https://stackoverflow.com/questions/39524855/how-to-trigger-off-callback-after-updating-state-in-redux
export const addStop = (stop) => dispatch => {
    dispatch({
        type: ADD_STOP, payload: {
            stop: stop,
        }
    });
    return Promise.resolve()
};


export const removeStop = (stop) => dispatch => {
    dispatch({
        type: REMOVE_STOP, payload: {
            stop: stop,
        }
    });
    return Promise.resolve()
};

export const resetStops = () => dispatch => {
    dispatch({
        type: RESET_STOPS
    });
    return Promise.resolve()
};


