import React from 'react';
import styled from 'styled-components';
import {formatDuration} from "../utils/DateUtils";

const DurationContainer = styled.span`
    width: 3rem;
    font-size: .75rem; 
    line-height: 1.125rem; 
    letter-spacing: normal; 
`;

class Duration extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <DurationContainer>{formatDuration(this.props.duration)}</DurationContainer>
        )
    }


}

export default Duration;