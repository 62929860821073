import _ from 'lodash';
import {carrierCustomizer, customizer} from "../utils/JsonUtils";
import {updateTimeRanges} from "../utils/FilterUtils";
import moment from "moment";
import {
    ADD_CARRIERS,
    ADD_STOP,
    DEALS_UPDATED,
    FILTER_DURATION,
    FILTER_INBOUND_ARRIVAL_TIMES,
    FILTER_INBOUND_DEPARTURE_TIMES,
    FILTER_OUTBOUND_ARRIVAL_TIMES,
    FILTER_OUTBOUND_DEPARTURE_TIMES,
    REMOVE_CARRIERS,
    REMOVE_STOP,
    RESET_CARRIERS,
    RESET_STATE,
    RESET_STOPS,
    SESSION_CREATED,
    SET_CABIN_CLASS,
    SET_CARRIER,
    SET_DEPART_DATE,
    SET_DESTINATION,
    SET_DESTINATION_DISPLAY,
    SET_FIELD_VALIDATION,
    SET_LOADING_ACTION,
    SET_ORIGIN,
    SET_ORIGIN_DISPLAY,
    SET_QUERY_PARAMS,
    SET_RETURN_DATE,
    SET_SEARCH_INTERVAL,
    SET_TRAVELERS,
    SET_TRIPTYPE,
    SET_VALIDATION,
    TOKEN_UPDATED,
    TRAVEL_EXCHANGE_COMPARISON_UPDATED
} from "../constants";
import {getLastSearch} from "../actions/GetLastSearch";

const initialState = {
    queryParams: {
        origin: '',
        originDisplay: '',
        originCityName: '',
        originStateName: '',
        originCountryName: '',
        originCountryFipsCode: '',
        destination: '',
        destinationDisplay: '',
        destinationCityName: '',
        destinationStateName: '',
        destinationCountryName: '',
        destinationCountryFipsCode: '',
        departDate: moment().format('YYYY-MM-DD'),
        returnDate: moment().add(7, 'd').format('YYYY-MM-DD'),
        adults: 1,
        children: 0,
        cabin: "Economy",
        isMobile: window.innerWidth <= 768,
        ...getLastSearch()
    },
    searchNum: 0,
    deals: [],
    filters: {
        stops: [0, 1, 2]
    },
    filterDetails: {
        stops: {},
        carriers: [],
        duration: {},
        times: {}
    },
    isValid: false,
    invalidFields: {
        origin: {name: "origin", isValid: false, msg: "Flights origin is required"},
        destination: {name: "destination", isValid: false, msg: "Flight destination is required"},
        departDate: {name: "departDate", isValid: false, msg: "Depart date is required"},
        returnDate:{name: "returnDate", isValid: false, msg: "Return date is required"},
        travelers: {name: "travelers", isValid: false, msg: "Minimum 1 adults passenger is required"},
        cabinClass:{name: "cabinClass", isValid: false, msg: "Can't define cabin class"},
    },
    token: '',
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case DEALS_UPDATED:
            const page = Number(action.payload.page);
            const reload = action.payload.done;
            const deals = reload ? action.payload.deals : [...state.deals, ...action.payload.deals]; //as long as (!done) than keep adding, when done replace

            if (!_.isEmpty(action.payload.deals)) {
                let oldStops = _.cloneDeep(state.filterDetails.stops);
                let oldCarriers = _.cloneDeep(state.filterDetails.carriers);
                let durationRange = _.cloneDeep(state.filterDetails.duration);
                let timeRanges = _.cloneDeep(state.filterDetails.times);

                if (_.isEmpty(durationRange) || reload) {
                    durationRange = action.payload.filterDetails.duration;
                } else {
                    if (action.payload.filterDetails.duration.min < durationRange.min) {
                        durationRange.min = action.payload.filterDetails.duration.min;
                    }

                    if (action.payload.filterDetails.duration.max > durationRange.max) {
                        durationRange.max = action.payload.filterDetails.duration.max;
                    }
                }

                if (_.isEmpty(timeRanges) || reload) {
                    timeRanges = action.payload.filterDetails.times;
                } else {
                    timeRanges = updateTimeRanges(timeRanges, action.payload.filterDetails.times);
                }

                const filterDetails = {
                    carriers: reload ? action.payload.filterDetails.carriers : _.mergeWith(oldCarriers, action.payload.filterDetails.carriers, carrierCustomizer),
                    stops: reload ? action.payload.filterDetails.stops : _.mergeWith(oldStops, action.payload.filterDetails.stops, customizer),
                    duration: durationRange,
                    times: timeRanges
                };

                return {
                    ...state,
                    isLoading: false,
                    deals: deals,
                    status: action.payload.status,
                    done: action.payload.done,
                    page: page,
                    filterDetails: filterDetails,
                };
            }

            let isLoading = true;
            if(action.payload.done && action.payload.status){
                isLoading = false;
            }

            return {
                ...state,
                isLoading: isLoading,
                deals: deals,
                page: page,
                status: action.payload.status,
                done: action.payload.done,
            };
        case SESSION_CREATED:
            return {
                ...state,
                filters: {
                    stops: [0, 1, 2]
                },
                filterDetails: {
                    stops: [],
                    carriers: {},
                    duration: {},
                    times: {}
                },
                sessionId: action.payload.sessionId
            };
        case SET_LOADING_ACTION:
            return {
                ...state,
                isLoading: action.payload.isLoading
            };
        case SET_QUERY_PARAMS:
            return {
                ...state,
                queryParams: {...state.queryParams , ...action.payload.query}
            };
        case SET_ORIGIN:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    origin: action.payload.origin,
                    originCityName: action.payload.originCityName,
                    originStateName: action.payload.originStateName,
                    originCountryName: action.payload.originCountryName,
                    originCountryFipsCode: action.payload.originCountryFipsCode
                },
            };
        case SET_ORIGIN_DISPLAY:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    originDisplay: action.payload.displayValue
                }
            };
        case SET_DESTINATION:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    destination: action.payload.destination,
                    destinationCityName: action.payload.destinationCityName,
                    destinationStateName: action.payload.destinationStateName,
                    destinationCountryName: action.payload.destinationCountryName,
                    destinationCountryFipsCode: action.payload.destinationCountryFipsCode
                }
            };
        case SET_DESTINATION_DISPLAY:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    destinationDisplay: action.payload.displayValue
                }
            };
        case SET_RETURN_DATE:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    returnDate: action.payload.returnDate
                }
            };
        case SET_DEPART_DATE:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    departDate: action.payload.departDate
                }
            };
        case SET_TRAVELERS:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    adults: action.payload.adults,
                    children: action.payload.children
                }
            };
        case SET_CABIN_CLASS:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    cabin: action.payload.cabin
                }
            };
        case SET_TRIPTYPE:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    oneWay: action.payload.oneWay,
                }
            };
        case ADD_STOP:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    stops: state.filters.stops.concat(action.payload.stop)
                }
            };
        case REMOVE_STOP:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    stops: state.filters.stops.filter(stop => stop !== action.payload.stop)
                }
            };
        case RESET_STOPS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    stops: [0 ,1, 2]
                }
            };
        case REMOVE_CARRIERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    carriers: [...(state.filters.carriers||[]), ...[action.payload.carrier]]
                }
            };
        case ADD_CARRIERS:
            const filteredCarriers = state.filters.carriers.filter((item) => item !== action.payload.carrier);
            return {
                ...state,
                filters: {
                    ...state.filters,
                    carriers: filteredCarriers
                }
            };
        case RESET_CARRIERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    carriers: [],
                }
            };
        case SET_CARRIER:
            let carriers = _.keys(state.filterDetails.carriers);
            carriers = carriers.filter(carrier => carrier !== action.payload.carrier); // todo: check race condition with filter details update
            return {
                ...state,
                filters: {
                    ...state.filters,
                    carriers: carriers
                }
            };
        case FILTER_DURATION:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    duration: action.payload.duration
                },
            };
        case FILTER_OUTBOUND_DEPARTURE_TIMES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    outboundDepartureTimeRange: action.payload.range,
                    outboundDepartureTimeRangeValue: action.payload.rangeValue
                }
            };
        case FILTER_INBOUND_DEPARTURE_TIMES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    inboundDepartureTimeRange: action.payload.range,
                    inboundDepartureTimeRangeValue: action.payload.rangeValue
                }
            };
        case FILTER_OUTBOUND_ARRIVAL_TIMES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    outboundArrivalTimeRange: action.payload.range,
                    outboundArrivalTimeRangeValue: action.payload.rangeValue
                }
            };
        case FILTER_INBOUND_ARRIVAL_TIMES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    inboundArrivalTimeRange: action.payload.range,
                    inboundArrivalTimeRangeValue: action.payload.rangeValue
                }
            };
        case TRAVEL_EXCHANGE_COMPARISON_UPDATED:
            return {
                ...state,
                travelExchangeComparisonList: action.payload
            };
        case TOKEN_UPDATED:
            return {
                ...state,
                token: action.payload
            };
        case RESET_STATE:
            return {
                ...state,
                deals: [],
                filters: {
                    stops: [0, 1, 2]
                },
                filterDetails: {
                    stops: {},
                    carriers: {},
                    duration: {},
                    times: {}
                }
            };
        case SET_VALIDATION:
            return {
                ...state,
                isValid: action.payload.isValid,
                invalidFields: action.payload.fields
            };
        case SET_FIELD_VALIDATION:
            let inValidField = {};
            inValidField[action.payload.field] = {name: action.payload.field, isValid: action.payload.isValid, msg: action.payload.msg};
            return {
                ...state,
                invalidFields: {...state.invalidFields, ...inValidField}
            };
        case SET_SEARCH_INTERVAL:
            return {
                ...state,
                searchNum: state.searchNum++
            };
        default:
            return {...state}

    }
}

export default rootReducer;


