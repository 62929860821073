import React from "react";
import styled from "styled-components";
import TravelersAndCabinClass from "./TravelersAndCabinClass";
import Origin from "./AutoComplete/Containers/Origin";
import Destination from "./AutoComplete/Containers/Destination";
import DateRange from "./DatePicker/Containers/DateRangePicker";
import {connect} from "react-redux";
import {fetchDeals} from "../actions/FetchDeals";
import {setLoadingAction} from "../actions/SetLoadingAction";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import DatePicker from "./DatePicker/Containers/DatePicker";
import FullScreenDialog from "./Dialog/FullScreenDialog";
import SearchButton from "./Buttons/SearchButton";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import TripType from "./TripType";
import {resetState} from "../actions/ResetState";
import {setSearchInterval} from "../actions/SetSearchInterval";
import _ from 'lodash'
//MA
import {fetchComparisonData} from "../actions/FetchComparisonData";

import Validation from "./Validation/Validation";
import {validateSearchParams} from "../actions/Validator";
import {recordSearch} from "../actions/recordSearch";
import {withRouter} from "react-router-dom";
import {setLastSearch} from "../actions/SetLastSearch";

import {searchEvent} from '../actions/DataLayers';
import {getLastSearch} from "../actions/GetLastSearch";


const SearchForm = styled.div`
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400; 
    font-size: 0.9rem;
    line-height: 1.4; 
    color: #524c61;
    background-color: white;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    display: grid;
    grid-template-columns: ${(props) => props.oneWay ? "1fr 1fr 1fr 1fr 0.2fr" : "1fr 1fr 2fr 1fr 0.2fr"}; 
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 0.5rem; 

    @media only screen and (max-width: 768px){
        grid-template-columns: 1fr;
        box-shadow: none;
    }
`;

const AutoCompleteGrouped = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        @media only screen and (min-width: 769px){
            grid-column: 1 / span 2;
        }
`;


class FlightsSearchForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openDialog: false,
            showError: false,
            onInit: true,
            showDefaultHeader: false
        };
    }

    componentDidMount(){
        if (!this.props.isLoading && this.state.onInit && (this.props.isMobile || window.innerWidth <= 768)) {
            this.openDialog();
            this.setState({onInit: false, showDefaultHeader: true});
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.isMobile){
            if(this.state.onInit){
                if (!_.isEqual(prevProps.isLoading, this.props.isLoading) && this.props.deals.length === 0 && !this.props.isLoading) {
                    this.openDialog();
                    this.setState({onInit: false, showDefaultHeader: true});
                }
            }else{
                if (!_.isEqual(prevProps.isLoading, this.props.isLoading) && this.state.showDefaultHeader){
                    this.setState({showDefaultHeader: false});
                }
            }
        }
    }

    renderDateInput = () => {
        if (this.props.oneWay) {
            return <DatePicker/>
        }
        return <DateRange label={"Depart Date"}/>
    };


    handleClick = () => {
        if(this.props.validateSearchParams(this.props.queryParams)){
            this.props.searchEvent(this.props.queryParams, this.props.location.search);
            this.props.setLastSearch(this.props.queryParams);
            this.props.resetState();
            this.props.setLoadingAction(true);
            this.props.recordSearch(this.props.queryParams);
            this.props.fetchDeals(this.props.queryParams);
            this.props.setSearchInterval();
            this.props.fetchComparisonData(this.props.queryParams, this.props.location.pathname);
            this.closeDialog();
        }else{
            this.setState({
                showError: true
            });
        }

    };

    closeDialog = () => {
        this.setState({
            openDialog: false
        })
    };

    openDialog = () => {
        this.setState({
            openDialog: true
        })
    };

    closeErrorAlert = () => {
        this.setState({showError: false});
    };

    renderSearchButton = () => {
        if (this.props.isMobile) {
            return <SearchButton buttonName={"Search"} onClick={this.handleClick} />
        }

        return (
            <IconButton
                aria-label="next"
                name="next"
                onClick={this.handleClick}
            >
                <SearchIcon style={{fontSize: 40, padding: '0.1rem'}}/>
            </IconButton>
        )
    };


    render() {
        if (this.props.isMobile && !this.state.openDialog) {
            return (
                <span>
                    <IconButton onClick={this.openDialog} style={{padding : "0"}}>
                        <EditTwoToneIcon />
                    </IconButton>
                </span>
            )
        }

        return (
            <FullScreenDialog
                showSiteHeader={this.state.showDefaultHeader}
                buttonName={"Edit"}
                title={"Edit Search"}
                onClose={this.closeDialog}
                open={this.state.openDialog}
                isMobile={this.props.isMobile}>
                <TripType />
                <SearchForm oneWay={this.props.oneWay}>
                    <AutoCompleteGrouped>
                        <Origin label={"From"} exclude={this.props.queryParams.destination}/>
                        <Destination label={"To"} exclude={this.props.queryParams.origin}/>
                    </AutoCompleteGrouped>
                    {this.renderDateInput()}
                    <TravelersAndCabinClass label={"Travelers & Cabin Class"}/>
                    {this.renderSearchButton()}
                </SearchForm>
                <Validation showError={this.state.showError} closeError={this.closeErrorAlert}/>
            </FullScreenDialog>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        queryParams: state.queryParams,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
        oneWay: (state.queryParams && Number(state.queryParams.oneWay)) || 0,
        isValid: state.isValid,
        invalidFields: state.invalidFields,
        deals: state.deals,
        isLoading: state.isLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchEvent: (params, urlParam) => dispatch(searchEvent(params, urlParam)),
        recordSearch: (searchParameters) => dispatch(recordSearch(searchParameters)),
        fetchDeals: (searchParameters) => dispatch(fetchDeals(searchParameters)),
        setLastSearch: (searchParameters) => setLastSearch(searchParameters),
        setLoadingAction: (value) => dispatch(setLoadingAction(value)),
        fetchComparisonData: (params, path) => dispatch(fetchComparisonData(params, path)),
        resetState: () => dispatch(resetState()),
        validateSearchParams: (params) => dispatch(validateSearchParams(params)),
        setSearchInterval: () => dispatch(setSearchInterval()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FlightsSearchForm));
