import React from 'react';

import styled from 'styled-components'

const TimeLineContainer = styled.div`
   display: flex;
   padding-top: .275rem;
   padding-right: 1.125rem;
   padding-bottom: .275rem;
   flex-direction: column;
   justify-content: space-between
   
   &:before {
       content: "";
       display: block;
       z-index: 2;
       width: .625rem;
       height: .625rem;
       border: .125rem solid #b2aebd;
       border-radius: 100%;
       background: #fff;
   }
   
   &:after {
       content: "";
       display: block;
       z-index: 2;
       width: .625rem;
       height: .625rem;
       border: .125rem solid #b2aebd;
       border-radius: 100%;
       background: #fff;
   }
`;

const Line = styled.div`
   position: relative;
   left: .35rem;
   display: flex;
   flex-grow: 1;
   
   &:after {
       position: relative;
       content: "";
       width: .125rem;
       background-color: #b2aebd;
   }
`;

class TimeLine extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const legData = this.props.legData;


        return (
            <TimeLineContainer>
               <Line></Line>
            </TimeLineContainer>
        )
    }


}

export default TimeLine;