import React from 'react'
import {connect} from 'react-redux'
import AutoComplete from "../AutoComplete";
import {setDestination} from "../../../actions/SetDestination";
import {setDisplayValue} from "../../../actions/SetDisplayValue";
import {SET_DESTINATION_DISPLAY} from "../../../constants";
import {fetchDestinations} from  "../../../actions/FetchDestinations";
import {setFieldValidation} from  "../../../actions/SetValidation";


const mapStateToProps = (state) => {
    return {
        value: state.queryParams !== undefined ? state.queryParams.destination : '',
        className: 'destination',
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
        displayValue: (state.queryParams && state.queryParams.destinationDisplay),
        destinationCityName: (state.queryParams && state.queryParams.destinationCityName),
        destinationStateName: (state.queryParams && state.queryParams.destinationStateName),
        destinationCountryName: (state.queryParams && state.queryParams.destinationCountryName),
        destinationCountryFipsCode: (state.queryParams && state.queryParams.destinationCountryFipsCode)
    }
};


function mapDispatchToProps(dispatch) {
    return {
        setValue: (value,destinationCityName,destinationStateName,destinationCountryName,destinationCountryFipsCode) => dispatch(setDestination(value,destinationCityName,destinationStateName,destinationCountryName,destinationCountryFipsCode)),
        setDisplayValue: (value) => dispatch(setDisplayValue(SET_DESTINATION_DISPLAY,value)),
        fetchDestinations: (value) => dispatch(fetchDestinations(value)),
        setFieldValidation: (isValid,msg) => dispatch(setFieldValidation(isValid,msg, 'destination')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete);
