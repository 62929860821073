import {ADD_CARRIERS, REMOVE_CARRIERS, RESET_CARRIERS, SET_CARRIER} from "../constants/index";


// https://stackoverflow.com/questions/39524855/how-to-trigger-off-callback-after-updating-state-in-redux
export const addCarriers = (carrier) => dispatch => {
    dispatch({
        type: ADD_CARRIERS, payload: {
            carrier: carrier,
        }
    });
    return Promise.resolve()
};

export const removeCarriers = (carrier) => dispatch => {
    dispatch({
        type: REMOVE_CARRIERS, payload: {
            carrier: carrier,
        }
    });
    return Promise.resolve()
};

export const resetCarriers = (carrier) => dispatch => {
    dispatch({
        type: RESET_CARRIERS, payload: {
            carrier: carrier,
        }
    });
    return Promise.resolve()
};

export const setCarrier = (carrier) => dispatch => {
    dispatch({
        type: SET_CARRIER, payload: {
            carrier: carrier,
        }
    });
    return Promise.resolve()
};

