import React from 'react';
import StopsFilter from "./StopsFilter";
import DurationFilter from "./DurationFilter";
import CarriersFilter from "./CarrierFilter";
import OutboundDepartureFilter from "./filters/containers/OutboundDepartureFilter";
import InboundArrivalFilter from "./filters/containers/InboundArrivalFilter";
import OutboundArrivalFilter from "./filters/containers/OutboundArrivalFilter";
import InboundDepartureFilter from "./filters/containers/InboundDepartureFilter";
import styled from "styled-components";
import {connect} from 'react-redux'
import FiltersSkeleton from "./Skeletons/Filters";
import FullScreenDialog from "./Dialog/FullScreenDialog";
import FiltersButton from "./Buttons/FiltersButton";


const FiltersContainer = styled.div`
    padding: 1rem; 
    margin-bottom: 15px; 
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400; 
    font-size: 0.9rem;
    line-height: 1.4; 
    color: #524c61; 
    border: 1px solid #e5ebf0;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    background-color: white;

    @media only screen and (max-width: 768px){
        box-shadow: none;
    }
`;

class ConnectedFilters extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openDialog: false,
        }
    }

    closeDialog = () => {
        this.setState({
            openDialog: false
        })
    };

    openDialog = () => {
        this.setState({
            openDialog: true
        })
    };

    render() {
        return (
        (this.props.isMobile && !this.state.openDialog && !this.props.isLoading) ?
            <div style={{backgroundColor: "#fff"}}>
                <FiltersButton buttonName={"Filters"} onClick={this.openDialog}/>
            </div>
            :
        this.props.isLoading ? (

            <FiltersSkeleton/>
        ) :
            ( this.props.deals.length > 0 ?
                <FullScreenDialog 
                    keepMounted
                    buttonName={"Filters"} 
                    title={"Edit Filters"} 
                    onClose={this.closeDialog} 
                    open={this.state.openDialog} 
                    isMobile={this.props.isMobile}>
                    <FiltersContainer style={this.props.style}>
                        <StopsFilter/>
                        <OutboundDepartureFilter name={"outboundDeparture"} title={'Outbound Takeoff Time'} step={10000} {...this.props}/>
                        <InboundDepartureFilter name={"inboundDeparture"} title={'Inbound Takeoff Time'} step={10000}/>
                        <OutboundArrivalFilter name={"outboundArrival"} title={'Outbound Landing Time'} step={10000}/>
                        <InboundArrivalFilter name={"outboundArrival"} title={'Inbound Landing Time'} step={10000}/>
                        <CarriersFilter/>
                        <DurationFilter step={10000}/>
                    </FiltersContainer>
                </FullScreenDialog>
            : <div/>) )
    }
}

const mapStateToProps = (state) => {
    return {
        deals: state.deals,
        isLoading: state.isLoading,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
    }
};

const Filters = connect(mapStateToProps, null)(ConnectedFilters);

export default Filters;