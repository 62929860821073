import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux'
import {addCarriers, removeCarriers, resetCarriers, setCarrier} from "../actions/FilterCarriers";
import {filterEvent} from "../actions/DataLayers";
import styled from "styled-components";

const CheckboxFilterContainer = styled.div`
    display: flex;
    justify-content: space-between; 
`;

const Reset = styled.h4`
    cursor: pointer;
    color: #2196f3;
`;

const mapStateToProps = (state) => {
    return {
        filteredCarriers: state.filters.carriers,
        options: state.filterDetails.carriers
    }
};


function mapDispatchToProps(dispatch) {
    return {
        addCarriers: (stops) => dispatch(addCarriers(stops)),
        removeCarriers: (stops) => dispatch(removeCarriers(stops)),
        resetCarriers: () => dispatch(resetCarriers()),
        setCarrier: (carrier) => dispatch(setCarrier(carrier)),
        filterEvent: (value) => dispatch(filterEvent(value))
    };
}

class CarrierFilter extends React.Component {

    constructor(props) {
        super(props);
    }

    handleInputChange = event => {
        let carrier = event.target.value;
        if (event.target.checked) {
            this.props.addCarriers(carrier).then(() => {
            });
        } else {
            this.props.removeCarriers(carrier).then(() => {
            });
        }

        // let carrierList = this.props.options;
        // let filteredCarriers = this.props.filteredCarriers;
        // let carriers = !_.isEmpty(carrierList) ? this.sortCarriers(_.values(carrierList)) : false;
        // let filteredCarriersSorted = !_.isEmpty(filteredCarriers) ? this.sortCarriers(_.values(filteredCarriers)) : false
        // //sorting the object in order to send it to analytics
        // carriers.forEach((option) => {
        //     if(filteredCarriersSorted.length > 1) {
        //         filteredCarriersSorted.forEach((carrier) => {
        //             option.signed = carrier == option.id ? false : true;
        //         })
        //     }
        // })
        this.props.filterEvent({
            filterName: 'Carrier',
            filterAction: 'Carriers click'
        })

    };


    sortCarriers = options => {
        if (_.isEmpty(options)) return options;

        return options.sort(function (a, b) {
            if(a.id === b.id) return 0;
            if (a.id === 'multiple') return -1;
            if (b.id === 'multiple') return 1;

            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
    };

    handleReset = () =>{
        this.props.resetCarriers();
    };

    handleClick = (carrier) => {
        this.props.setCarrier(carrier)
    };

    render() {

        const options = this.props.options;
        const carriers = !_.isEmpty(options) ? this.sortCarriers(_.values(options)) : false;

        if (!carriers) {
            return <div/>
        }
        return (
            <div>
                <div>
                    <CheckboxFilterContainer>
                        <h4>Airlines</h4>
                        <Reset onClick={this.handleReset}>Reset</Reset>
                    </CheckboxFilterContainer>
                    {carriers.map((carrier) => {
                        return (
                            <CheckboxFilterContainer key={carrier.id}>
                                <div >
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={carrier.id}
                                            checked={!_.includes(this.props.filteredCarriers, carrier.id)}
                                            onChange={(e) => {
                                                this.handleInputChange(e)
                                            }}
                                        />
                                        {`${carrier.name}`}
                                    </label>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div>{carrier.price}</div>
                                </div>
                            </CheckboxFilterContainer>)

                    })}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierFilter);

