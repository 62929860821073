import React from "react";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {connect} from "react-redux";
import CustomRadio from "./Buttons/CustomRadio";
import _ from 'lodash'
import {setTripType} from "../actions/SetTripType";


class ConnectedTripType extends React.Component {

    constructor(props) {
        super(props);
    }

    handleOneWayChange = (e) => {
        this.setState({
            tripType: e.target.value,
        })
        this.props.setTripType(e.target.value);
    };

    render() {
        return (
                <FormControl component="fieldset" style={{marginTop: "1rem"}}>
                    <RadioGroup aria-label="position" name="position" value={this.props.oneWay}
                                onChange={this.handleOneWayChange} row>
                        <FormControlLabel
                            value={0}
                            control={<CustomRadio checked={this.props.oneWay === 0} value={0}/>}
                            label="Round-Trip"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={1}
                            control={<CustomRadio checked={this.props.oneWay === 1} value={1}/>}
                            label="One-Way"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        queryParams: state.queryParams,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
        oneWay: (state.queryParams && Number(state.queryParams.oneWay)) || 0,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTripType: (tripType) => dispatch(setTripType(tripType)),
    };
};


const TripType = connect(mapStateToProps, mapDispatchToProps)(ConnectedTripType);


export default TripType;