import React from 'react';

import styled from 'styled-components'
import DateTime from "./DateTime";
import SegmentDetails from "./SegmentDetails";


class Segment extends React.Component {
    constructor(props) {
        super(props)
    }

    /*

    "Id": 46,
    "OriginStation": {},
    "DestinationStation": {},
    "DepartureDateTime": "2019-10-01T10:00:00",
    "ArrivalDateTime": "2019-10-01T14:35:00",
    "Carrier": {},
    "OperatingCarrier": 1044,
    "Duration": 275,
    "FlightNumber": "415",
    "JourneyMode": "Flight",
    "Directionality": "Outbound"
     */


    render() {

        const segment = this.props.segment;

        // const segment = {
        //     Id: 46,
        //     OriginStation: {
        //         Id: 16995,
        //         ParentId: 7617,
        //         Code: "TLV",
        //         Type: "Airport",
        //         Name: "Ben Gurion Intl"
        //     },
        //     "DestinationStation": {
        //         Id: 9252,
        //         ParentId: 322,
        //         Code: "ADD",
        //         Type: "Airport",
        //         Name: "Addis Ababa"
        //     },
        //     DepartureDateTime: "2019-10-01T10:00:00",
        //     ArrivalDateTime: "2019-10-01T14:35:00",
        //     Carrier: {
        //         Id: 1044,
        //         Code: "AA",
        //         Name: "American Airlines",
        //         ImageUrl: "https://s1.apideeplink.com/images/airlines/ET.png",
        //         DisplayCode: "AA"
        //     },
        //     OperatingCarrier: 1044,
        //     Duration: 275,
        //     FlightNumber: "415",
        //     JourneyMode: "Flight",
        //     Directionality: "Outbound"
        // };

        return (
            <SegmentDetails
                carrier={new Array(segment.Carrier)}
                flightNumber={segment.FlightNumber}
                duration={segment.Duration}
                departure={segment.DepartureDateTime}
                arrival={segment.ArrivalDateTime}
                originStation={segment.OriginStation}
                destinationStation={segment.DestinationStation}
            />
        )
    }


}

export default Segment;