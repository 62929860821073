import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash'

const DealContainer = styled.div`
    width: 670,
    margin: 0 auto; 
    margin-bottom: 15px; 
    display: grid; 
    grid-auto-columns: 70% 30%; 
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400; 
    font-size: 1rem;
    line-height: 1.5rem; 
    color: #524c61;
    border: 1px solid #e5ebf0;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    background-color: white;

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Legs = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    display: grid; 
    flex-direction: column; 
    border-right: 1px solid #e5ebf0;  

    @media only screen and (max-width: 768px) {
        border-right: none;

        display: flex; 
        flex-direction: column; 
    }  
`;


const PriceContainer = styled.div`
    grid-area: 1 / 2 / 2 / 3; 
    display: grid;
    align-self: center 
    justify-self: center; 

    @media only screen and (max-width: 768px) {
        padding: 0.5em 0;
    }
`;

class DealSkeleton extends React.Component {
    constructor(props) {
        super(props);


    }

    render() {


            return (
                <DealContainer>
                        <Legs>
                            <CardHeader
                                avatar={<Skeleton variant="text" width={40} height={40}/>}
                                title={<Skeleton height={10} width="80%"
                                                 style={{marginBottom: 'auto', marginLeft: '10%'}}/>}
                                subheader={<Skeleton height={10} width="40%"
                                                     style={{marginBottom: 'auto', marginLeft: '30%'}}/>}
                                style={{borderBottom: '1px solid #e5ebf0'}}
                            />

                            <CardHeader
                                avatar={<Skeleton variant="text" width={40} height={40}/>}
                                title={<Skeleton height={10} width="80%"
                                                 style={{marginBottom: 'auto', marginLeft: '10%'}}/>}
                                subheader={<Skeleton height={10} width="40%"
                                                     style={{marginBottom: 'auto', marginLeft: '30%'}}/>}
                            />
                        </Legs>
                        <PriceContainer>
                            <div>
                                <Skeleton height={30} width="40%" style={{marginBottom: 'auto', marginLeft: '30%'}}/>
                            </div>
                            <div>
                                <Skeleton variant="text" width={80} height={10}
                                          style={{marginBottom: 'auto', marginLeft: '10%'}}/>
                            </div>
                            <div>
                                <Skeleton variant="text" width={100} height={40}/>
                            </div>
                        </PriceContainer>
                </DealContainer>
            );

    }
}

export default DealSkeleton;