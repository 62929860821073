
export const setDisplayValue = (type, displayValue) => dispatch => {
    dispatch({
        type: type, payload: {
            displayValue: displayValue
        }
    });
    return Promise.resolve()

};
