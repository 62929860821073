import {SET_CABIN_CLASS} from "../constants";

export const setCabinClass = (cabin) => dispatch => {

    dispatch({
        type: SET_CABIN_CLASS, payload: {
            cabin: cabin
        }
    });
    return Promise.resolve()

};
