import {SET_VALIDATION, SET_FIELD_VALIDATION} from "../constants";

export const setValidation = (isValid,fields) => dispatch => {
    dispatch({
        type: SET_VALIDATION, payload: {
            isValid: isValid,
            fields: fields,
        }
    });
    return Promise.resolve()

};

export const setFieldValidation = (isValid,msg,field) => dispatch => {
    dispatch({
        type: SET_FIELD_VALIDATION, payload: {
            isValid: isValid,
            field: field,
            msg: msg
        }
    });
    return Promise.resolve()

};