import queryString from "query-string";


export const searchEvent = (searchParameters, urlQueryString) => {
    return () => {
        const urlParameters = queryString.parse(urlQueryString);
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            'event': 'allFieldsValidated',
            'utmSource': urlParameters.utm_campaign,
            'vertical': 'flights',
            'searchParameters':{
                'isRoundTrip': searchParameters.oneWay,
                'passengers': searchParameters.adults,
                'children': searchParameters.children,
                'flightClass': searchParameters.cabin,
                'origin':{
                    'entityType': 'airport',
                    'entity':{
                        'threeCode': searchParameters.origin,
                        'name': searchParameters.originDisplay,
                        'cityName': searchParameters.originCityName,
                        'stateName':searchParameters.originStateName,
                        'countryName': searchParameters.originCountryName,
                        'countryFipsCode':searchParameters.originCountryFipsCode
                    },
                },
                'destination':{
                    'entityType':'airport',
                    'entity':{
                        'threeCode': searchParameters.destination,
                        'name': searchParameters.destinationDisplay,
                        'cityName': searchParameters.destinationCityName,
                        'stateName':searchParameters.destinationStateName,
                        'countryName': searchParameters.destinationCountryName,
                        'countryFipsCode':searchParameters.destinationCountryFipsCode
                    },
                },
                'departDate': searchParameters.departDate,
               'returnDate': searchParameters.returnDate
            }
        });
    }
};

export const dealEvent = (deal) => {
    return () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'viewDeal',
            'price': deal.price,
            'partner_website': deal.partner,
            'outbound_duration': deal.durationIn,
            'inbound_duration':  deal.durationOut
        });
    }
}

export const sidePlacementEvent = (metaData) => {

    return () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'compare_component',
            'partner_name': metaData.advertiser,
            'btn_position': metaData.position,
            'placement_location': 'sidebar'
        })
    }
}

export const sidePlacementEventMobile = (metaData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'compare_component_mobile',
        'partner_name': metaData.advertiser,
        'btn_position': metaData.position,
        'placement_location': 'sidebar_mobile'
    })
}

export const filterEvent = (metaData) => {
    return () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'filter',
            'filter_name': metaData.filterName,
            'filter_action': metaData.filterAction
        });
    }

}