// https://stackoverflow.com/questions/39524855/how-to-trigger-off-callback-after-updating-state-in-redux
import moment from 'moment';

export const setTimeRangeFilter = (type, range) => dispatch => {
    let rangeFormatted = range.map(time => moment(time).format("YYYY-MM-DDTHH:mm:00"));

    dispatch({
        type: type, payload: {
            range: rangeFormatted,
            rangeValue: range
        }
    });
    return Promise.resolve()

};