import {SET_DEPART_DATE} from "../constants";

export const setDepartDate = (departDate) => dispatch => {

    dispatch({
        type: SET_DEPART_DATE, payload: {
            departDate: departDate
        }
    });
    return Promise.resolve()

};
