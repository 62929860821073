import {SET_DESTINATION} from "../constants";

export const setDestination = (destination,destinationCityName,destinationStateName,destinationCountryName,destinationCountryFipsCode) => dispatch => {

    dispatch({
        type: SET_DESTINATION, payload: {
            destination: destination,
            destinationCityName: destinationCityName,
            destinationStateName: destinationStateName,
            destinationCountryName: destinationCountryName,
            destinationCountryFipsCode: destinationCountryFipsCode
        }
    });
    return Promise.resolve()

};
