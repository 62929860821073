import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import styled from "styled-components";
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(theme => ({
  root:{
    width: '25%',
    height: '30%',
    padding: '1em',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#2196f3',
  },
  title: {
    flex: 1,
    fontSize: '1rem',
    fontWeight: '600',
  },
  text: {
    textAlign: "center",
  },
  content: {
    textAlign: 'center'
  },
  btnContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1em'
  }
}));

const CustomButton = styled.button`
    color: #fff !important;
    text-decoration: none;
    background: #2196f3;
    padding: .375rem 1.125rem;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    font-size: 1rem;
    
    :hover {
        background: #3e739c;
        letter-spacing: 1px;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
        padding: .375rem 1.125rem;
    }

        
    :active {
        background: #3e739c;
        letter-spacing: 1px;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
        padding: .375rem 1.125rem;
    }

    @media only screen and (max-width: 768px) {
        order: 2;
        justify-self: center;

            
        :hover {
            letter-spacing: 0;
        }

            
        :active {
            letter-spacing: 0;
        }

    } 
    
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />; //timeout={600}
});

export default function TimeoutAlertWithButtons(props) {
  const classes = useStyles();



  const handleOnActionFirst = () => {
    if(props.onActionFirst){
      props.onActionFirst();
    }
  };

  const handleOnActionSecond = () => {
    if(props.onActionSecond){
      props.onActionSecond();
    }
  };


  return (
      <div>
        <Dialog style={{padding: '1em'}} open={props.open} TransitionComponent={Transition} keepMounted={true} scroll={'paper'}>
          <AppBar className={classes.appBar}>
          </AppBar>
          <DialogContent className={classes.content}>
            <div>
              <Typography className={classes.text}>
                {props.msg}
              </Typography>
              {props.imgPath &&
              <div>
                <img src={props.imgPath} alt='' width="50%" height="50%"/>
              </div>
              }
            </div>
            <div className={classes.btnContainer}>
              <CustomButton autoFocus onClick={handleOnActionFirst} className={classes.btn} >
                {props.btnFirst}
              </CustomButton>
            </div>

          </DialogContent>
        </Dialog>
      </div>
  );
}

