import React, { Component } from 'react'
import TimeoutAlertWithButtons from "../Dialog/TimeoutAlertWithButtons";
import {connect} from 'react-redux';
import _ from 'lodash'
import {fetchDeals} from "../../actions/FetchDeals";
import {setSearchInterval} from "../../actions/SetSearchInterval";
import {resetState} from "../../actions/ResetState";
import {setLoadingAction} from "../../actions/SetLoadingAction";
import {withRouter} from "react-router-dom";
import {fetchComparisonData} from "../../actions/FetchComparisonData";

class RefreshResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMsg: false,
      timer: null
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.status, this.props.status) || !_.isEqual(prevProps.searchNum, this.props.searchNum)) {
      if(this.props.done && this.props.status && this.props.deals.length > 0){
        this.setTimer();
      }
    }
  }



  setTimer = () => {
    setTimeout(() => {
      this.setState({showMsg: true});
    }, 1000 * 60 * 15 );
  };

  clearTimer(){
    clearTimeout(this.state.timer);
  }

  refreshResults= () => {
    this.clearTimer();
    this.props.resetState();
    this.props.setLoadingAction(true);
    this.props.fetchDeals(this.props.queryParams);
    this.props.fetchComparisonData(this.props.queryParams, this.props.location.pathname);
    this.props.setSearchInterval();
    this.closeMsg();
  };

  resetSearch = () => {
    this.clearTimer();
    this.props.resetState();
    this.closeMsg();
  };

  closeMsg= () => {
    this.setState({showMsg: false});
  };

  render() {
    return (
        <div>
          <TimeoutAlertWithButtons
              title={'Refresh Results'}
              msg={'Please refresh results'}
              onClose={this.closeMsg}
              onActionFirst={this.refreshResults}
              onActionSecond={this.resetSearch}
              open={this.state.showMsg}
              btnFirst={"Refresh Results"}
              btnSecond={"New Search"}
              showSecondBtn={false}
              imgPath={'/assets/images/refreshGif.gif'}
          />
        </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingAction: (value) => dispatch(setLoadingAction(value)),
    fetchDeals: (searchParameters) => dispatch(fetchDeals(searchParameters)),
    setSearchInterval: () => dispatch(setSearchInterval()),
    fetchComparisonData: (searchParameters, path) => dispatch(fetchComparisonData(searchParameters, path)),
    resetState: () => dispatch(resetState()),
  };
};


const mapStateToProps = (state) => {
  return {
    deals: state.deals,
    status: state.status,
    done: state.done,
    queryParams: state.queryParams,
    searchNum: state.searchNum
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RefreshResults));