import React from 'react';
import styled from 'styled-components';
import BookingOption from "./BookingOption";

import {Select, MenuItem} from '@material-ui/core';
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import {connect} from "react-redux";
import {recordClickout} from "../actions/recordClickout";
import {dealEvent} from '../actions/DataLayers';
import {withRouter} from 'react-router-dom'
import _ from "lodash";

const PriceContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-items: center; 
    text-align: center; 
    position:relative;

    @media only screen and (max-width: 768px) {
        display: grid;
        grid-template-rows: 1fr 1fr;
    }
`;

const PriceWrapper = styled.div`
 
    @media only screen and (max-width: 768px) {
        order: 2;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        text-align: center;
        position: relative;
        padding: 0.25em 0;
    }
`;

const PricingOptionsContainer = styled.div`
    background-color: white; 
    z-index: 1;
    position: absolute;
    top: 47px;
    box-shadow: 0 2px 5px 0 rgba(23,35,44,0.24),0 -1px 5px 0 rgba(23,35,44,0.2);
`;

const DisplayPrice = styled.div`
    color: #212a30;
    font-size: 24px; 
    font-weight: 600; 

    @media only screen and (max-width: 768px) {
        order: 3;
        justify-self: end;
    }
`;

const TravelersCount = styled.span`
    color: #0072c6;
    font-size: 0.75rem;
    font-weight: 500;
    vertical-align: middle;
`;

const DisplayAgent = styled.div`
    color: #212a30;
    text-decoration: none; 

    @media only screen and (max-width: 768px) {
        order: 1;
        justify-self: start;
    }
`;


const ViewDeal = styled.a`
    color: #fff !important;
    text-decoration: none;
    background: #2196f3;
    padding: .375rem 1.125rem;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    
    :hover {
        background: #3e739c;
        letter-spacing: 1px;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
        padding: .375rem 1.125rem;
    }

        
    :active {
        background: #3e739c;
        letter-spacing: 1px;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
        padding: .375rem 1.125rem;
    }

    @media only screen and (max-width: 768px) {
        order: 2;
        justify-self: center;

            
        :hover {
            letter-spacing: 0;
        }

            
        :active {
            letter-spacing: 0;
        }

    } 
    
`;

// const ViewDealWrapper = styled.a`
   
//     @media only screen and (max-width: 768px) {
//         order: 2;
//         justify-self: center;
//     }
// `;

class Price extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayBookingOptions: false
        }
    }

    showBookingOptions() {
        this.setState({
            displayBookingOptions: !this.state.displayBookingOptions
        })
    }

    showTravelersCount() {
        if (this.props.travelers > 1) {
            return <TravelersCount> &#215;{this.props.travelers}</TravelersCount>
        }
    };

    clickoutDeal(ota, price, currency, flightInDuration, flightOutDuration)  {

        this.props.recordClickout({
            ota: ota,
            price: price,
            currency: currency,
            dealToken: this.props.dealToken,
            documentPath: this.props.location.search
        });
        this.props.dealEvent({
            price: price,
            partner: 'Kayak',
            durationIn: flightInDuration,
            durationOut: flightOutDuration
        })
    };

    render() {

        const [pricingOption] = this.props.data;
        const displayPrice = pricingOption.DisplayPrice;
        const price = pricingOption.Price;
        const currency = pricingOption.Currency;
        const url = pricingOption.Url;
        const [agent] = pricingOption.Agents;
        const durationIn = pricingOption.durationIn;
        const durationOut = pricingOption.durationOut;
        const flightInDuration = `${_.floor(durationIn / 60)}h ${durationIn % 60}m`;
        const flightOutDuration = `${_.floor(durationOut / 60)}h ${durationOut % 60}m`;

        return (
            <PriceContainer>
                <PriceWrapper className={"price"}>
                    <DisplayPrice className={"displayPrice"}>
                        {displayPrice}
                        {this.showTravelersCount()}
                    </DisplayPrice>
                    <DisplayAgent onClick={() => this.showBookingOptions()}>{agent.Name}</DisplayAgent>
                    <ViewDeal href={url}
                              target='_blank'
                              onClick={() => this.clickoutDeal(agent.Name, price, currency, flightInDuration, flightOutDuration)}>View Deal</ViewDeal>
                </PriceWrapper>

                <FormControl>
                    <Select displayEmpty value={""}>
                        <MenuItem value="" disabled>
                            {`Booking Options (${this.props.data.length})`}
                        </MenuItem>
                        {this.props.data.map((pricingOption, index) => {
                            const displayPrice = pricingOption.DisplayPrice;
                            const price = pricingOption.Price;
                            const currency = pricingOption.Currency;
                            const url = pricingOption.Url;
                            const [agent] = pricingOption.Agents;
                            const durationIn = pricingOption.durationIn;
                            const durationOut =  pricingOption.durationOut;
                            const flightInDuration = _.isEmpty(durationIn) ? '' : `${_.floor(durationIn / 60)}h ${durationIn % 60}m`;
                            const flightOutDuration = _.isEmpty(durationOut) ? '' : `${_.floor(durationOut / 60)}h ${durationOut % 60}m`;

                            return (
                                <MenuItem key={index} fullWidth value={agent.Name}>
                                    <BookingOption
                                        key={index}
                                        url={url}
                                        agentName={agent.Name}
                                        displayPrice={displayPrice}
                                        onClick={() => this.clickoutDeal(agent.Name, price, currency, flightInDuration, flightOutDuration)}
                                    />
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>


                {/*<PricingOptionsContainer*/}
                {/*style={this.state.displayBookingOptions ? {display: 'block'} : {display: 'none'}}*/}
                {/*/>*/}
                {/*<ul style={{listStyleType: 'none', padding: '0.5rem', margin: 0}}>*/}
                {/*{pricingOptions.map((pricingOption, index) => {*/}
                {/*return (*/}
                {/*<BookingOption*/}
                {/*key={index}*/}
                {/*deeplink={pricingOption.Url}*/}
                {/*agentName={pricingOption.Agents[0].Name}*/}
                {/*price={pricingOption.Price}*/}
                {/*/>*/}
                {/*)*/}
                {/*})}*/}
                {/*</ul>*/}
                {/*</PricingOptionsContainer>*/}
            </PriceContainer>

        )
    }


}

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        recordClickout: (dealInfo) => dispatch(recordClickout(dealInfo)),
        dealEvent: (dealInfo) => dispatch(dealEvent(dealInfo))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Price));