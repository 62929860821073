import React from 'react';
import DateTime from "./DateTime";
import styled from 'styled-components'
import Offset from "./Offset";

const TimeWithOffsetContainer = styled.div``;

const SuperscriptOffset = (props) => {

    const Superscript = styled.sup`
        font-size: 0.7rem;
        font-weight: 100;
    `;

    return <Superscript>{props.offset}</Superscript>
};

class TimeWithOffset extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <TimeWithOffsetContainer>
                <DateTime dateTime={this.props.dateTime} format={this.props.format} style={{display: "inline-block"}} />
                <Offset dateTime={this.props.dateTime} offsetFrom={this.props.offsetFrom}  format={this.props.format} wrapperComponent={SuperscriptOffset} />
            </TimeWithOffsetContainer>
        )
    }
}

export default TimeWithOffset;
