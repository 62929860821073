import React, {useState} from 'react'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from "@material-ui/core/styles/index";
import styled from 'styled-components'
import _ from 'lodash';

const CarouselContainer = styled.div`
    padding: 0.1rem;
    display: flex;
`;

const CarouselOptions = styled.div`
    width: 100%;
    align-self: center;
    text-align: center;
    font-size: 1.3rem;
`;

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));

const iconStyling = {
    fontSize: '1.5rem',
};


export function Carousel(props) {

    const classes = useStyles();
    const [current, setCurrent] = useState(props.cabin);

    const next = () => {
        const index = props.options.indexOf(current) || 0;
        let next = (index + 1) % props.options.length;

        setCurrent(props.options[next]);
        props.onChange(props.options[next]);
    };

    const previous = () => {
        const index = props.options.indexOf(current) || 4;
        let previous = (index - 1) % props.options.length;

        setCurrent(props.options[previous]);

        props.onChange(props.options[previous]);
    };

    return (
        <CarouselContainer {...props}>
            <IconButton
                className={classes.button}
                aria-label="previous"
                name="previous"
                onClick={() => previous()}
            >
                <ArrowBackIosOutlinedIcon style={iconStyling}/>
            </IconButton>
            <CarouselOptions>
                {props.cabin}
            </CarouselOptions>
            <IconButton
                className={classes.button}
                aria-label="next"
                name="next"
                onClick={() => next()}
            >
                <ArrowForwardIosOutlinedIcon style={iconStyling}/>
            </IconButton>
        </CarouselContainer>
    )
}