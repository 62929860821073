import React from 'react';
import _ from 'lodash';
import StyledHorizontalBanner from './StyledHorizontalBanner'



class HorizontalBannerList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let bannerDataList = this.props.bannerDataList ? this.props.bannerDataList : [];

        return (
            <div>
                {bannerDataList.map((value, index) => {
                        return (
                                <StyledHorizontalBanner
                                    key={index}
                                    headline={value.headline}
                                    description={value.description}
                                    display_url={value.display_url}
                                    click_url={value.click_url}
                                    image_url={value.small_image_url}
                                    ctaText={this.props.ctaText}
                                    logoImageHeight={this.props.logoImagesHeight}
                                    sidePlacement={this.props.sidePlacement}
                                />
                        )
                })}
            </div>
            
        )
    }
}
export default HorizontalBannerList;

