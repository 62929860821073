export const DEALS_UPDATED = "DEALS_UPDATED";
export const SET_LOADING_ACTION = "SET_LOADING_ACTION";
export const FILTER_STOPS = "FILTER_STOPS";
export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const SESSION_CREATED = "SESSION_CREATED";
export const ADD_STOP = "ADD_STOPS";
export const REMOVE_STOP = "REMOVE_STOPS";
export const RESET_STOPS = "RESET_STOPS";
export const FILTER_DURATION = "FILTER_DURATION";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const ADD_CARRIERS = "ADD_CARRIERS";
export const REMOVE_CARRIERS = "REMOVE_CARRIERS";
export const RESET_CARRIERS = "RESET_CARRIERS";
export const SET_CARRIER = "SET_CARRIER";
export const SET_ORIGIN = "SET_ORIGIN";
export const SET_DESTINATION = "SET_DESTINATION";
export const SET_DEPART_DATE = "SET_DEPART_DATE";
export const SET_RETURN_DATE = "SET_RETURN_DATE";
export const SET_CABIN_CLASS = "SET_CABIN_CLASS";
export const SET_TRAVELERS = "SET_TRAVELERS";
export const FILTER_OUTBOUND_DEPARTURE_TIMES = "FILTER_OUTBOUND_DEPARTURE_TIMES";
export const FILTER_INBOUND_DEPARTURE_TIMES = "FILTER_INBOUND_DEPARTURE_TIMES";
export const FILTER_OUTBOUND_ARRIVAL_TIMES = "FILTER_OUTBOUND_ARRIVAL_TIMES";
export const FILTER_INBOUND_ARRIVAL_TIMES = "FILTER_INBOUND_ARRIVAL_TIMES";
export const TRAVEL_EXCHANGE_COMPARISON_UPDATED = "TRAVEL_EXCHANGE_COMPARISON_UPDATED";
export const TOKEN_UPDATED = "TOKEN_UPDATED";
export const SET_TRIPTYPE = "SET_TRIPTYPE";
export const RESET_STATE = "RESET_STATE";
export const SET_ORIGIN_DISPLAY = "SET_ORIGIN_DISPLAY";
export const SET_DESTINATION_DISPLAY = "SET_DESTINATION_DISPLAY";
export const SET_VALIDATION = "SET_VALIDATION";
export const SET_FIELD_VALIDATION = "SET_FIELD_VALIDATION";
export const SET_SEARCH_INTERVAL = "SET_SEARCH_INTERVAL";






