import React, {Component} from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import {formatDate, parseDate} from 'react-day-picker/moment';
import Helmet from 'react-helmet';
import styled from "styled-components";
import moment from "moment/moment";
import _ from 'lodash';

const datePickerInputStyles = {
    padding: '42px 0 9px 15px',
    fontSize: '18px',
    fontWeight: '500',
    border: 'none',
    width: '100%',
    borderRight: '1px #9e9e9e73 solid',
    boxSizing: 'border-box',
    outline: 'none'
};

const InputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
`;

class DateRangePicker extends Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.setDates();
        }
    }


    handleFromChange(from) {

        let to = this.props.to;

        if (moment(from).isAfter(moment(to))) {
            to = from;
        }

        this.props.fromHandler(moment(from).format('YYYY-MM-DD'));
        this.props.toHandler(moment(to).format('YYYY-MM-DD'));
        this.to.input.focus();

    }

    handleToChange(to) {
        this.to.hideDayPicker();
        this.props.toHandler(moment(to).format('YYYY-MM-DD'));
    }

    setDates() {

        let from = (this.props.from && moment(this.props.from));
        let to = (this.props.to && moment(this.props.to));

        if (!from || !to) {
            return;
        }

        if (from.isAfter(to)) {
            to = from;
        }

        this.props.fromHandler(from.format('YYYY-MM-DD'));
        this.props.toHandler(to.format('YYYY-MM-DD'));
    }

    render() {

        let from = moment(this.props.from).toDate();
        let to = moment(this.props.to).toDate();

        const modifiers = {start: from, end: to};
        return (
            <div>
                <div className="InputFromTo" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                    <div style={{position: 'relative'}}>
                        <InputLabel onClick={() => this.from.getInput().focus()}>Depart Date</InputLabel>
                        <DayPickerInput style={{width: '100%'}}
                                        inputProps={{readOnly: true, style: datePickerInputStyles}}
                                        ref={el => (this.from = el)}
                                        value={from}
                                        placeholder="-"
                                        format="LL"
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        dayPickerProps={{
                                            selectedDays: [from, {from, to}],
                                            disabledDays: [{before: new Date()}],
                                            modifiers,
                                            numberOfMonths: 2
                                        }}
                                        onDayChange={this.handleFromChange}
                        />
                    </div>
                    <span className="InputFromTo-to">
                        <div style={{position: 'relative'}}>
                            <InputLabel onClick={() => this.to.getInput().focus()}>Return Date</InputLabel>
                              <DayPickerInput style={{width: '100%'}}
                                              inputProps={{readOnly: true, style: datePickerInputStyles}}
                                              ref={el => (this.to = el)}
                                              value={to}
                                              placeholder="-"
                                              format="LL"
                                              formatDate={formatDate}
                                              parseDate={parseDate}
                                              dayPickerProps={{
                                                  selectedDays: [from, {from, to}],
                                                  disabledDays: [{before: from}, {before: new Date()}],
                                                  modifiers,
                                                  month: from,
                                                  fromMonth: from,
                                                  numberOfMonths: 2,
                                              }}
                                              onDayChange={this.handleToChange}/>
                        </div>
                        </span>
                    <Helmet>
                        <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 550px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
                    </Helmet>
                </div>
            </div>

        );
    }


}

export default DateRangePicker;