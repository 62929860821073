import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux'
import HorizontalBannerList from "../HorizontalBannerList";
import StyledHorizontalBanner from '../StyledHorizontalBanner'
import {sidePlacementEventMobile} from "../../../actions/DataLayers";




const mapStateToProps = state => {
    return {
        bannerDataList: state.travelExchangeComparisonList,
        sidePlacement: sidePlacementEventMobile
    }
};



class ConnectedHorizontalBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    validateIndex = (list,index) => {
      return !_.isEmpty(list) && index !== undefined && list.length > index
    };

    getBanner = (list,index) => {
        return this.validateIndex(list,index) ? list[index] : undefined
    };

    render() {
        let banner = this.getBanner(this.props.bannerDataList,this.props.index);

        return (
             banner ? 
            <StyledHorizontalBanner
                headline={banner.headline}
                description={banner.description}
                display_url={banner.display_url}
                click_url={banner.click_url}
                image_url={banner.small_image_url}
                ctaText={"Select"}
                logoImageHeight={"52px"}
                sidePlacement = {this.props.sidePlacement}
            />
            : 
            <div></div>
        )

    }

}
const HorizontalBanner = connect(mapStateToProps, null)(ConnectedHorizontalBanner);
export default HorizontalBanner;

