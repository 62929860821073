import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from "styled-components";
import moment from 'moment';
import FullScreenDialog from "../../Dialog/FullScreenDialog";
import _ from 'lodash';


const DateReangeInline = styled.div`
    z-index: 100;
    background: #fff;
    overflow-y: scroll;
`;

const DateRangeContainer = styled.div`
  position: relative; 
`;

const CustomInput = styled.input`
        padding: 42px 0 9px 15px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        width: 100%;
        border: 1px #9e9e9e73 solid;
        box-sizing: border-box;
        outline: none; 
`;


const DateRangeInputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
`;


class MobileDateRangePicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      from: moment().toDate(),
      to: moment().add(7, 'd').toDate(),
      focusedFrom: false,
      focusedTo: false,
      openDialog: false,
      title: ''
    };
  }

  componentDidMount(){
    if (this.props.from !== undefined){
        this.setState({from: moment(this.props.from).toDate()})
    }

    if (this.props.to !== undefined) {
        this.setState({to: moment(this.props.to).toDate()})
    }
  }

  componentDidUpdate(prevProps){
    if (! _.isEqual(prevProps, this.props)){
        this.setState({
            from: moment(this.props.from).toDate(),
            to: moment(this.props.to).toDate()
        })
    }
  }

  handleFromDayClick = (from, modifiers = {}) => {

    if (modifiers.disabled) {
      return;
    }

    let to = this.state.to;

    if (moment(from).isAfter(moment(to))){
        to = from;
    }

    this.setState({from: from, to: to});
    this.handleFromBlur();
    this.handleToFocus();

    this.props.fromHandler(moment(from).format('YYYY-MM-DD'));
    this.props.toHandler(moment(to).format('YYYY-MM-DD'));
  };

  handleToDayClick = (to, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    
    this.props.toHandler(moment(to).format('YYYY-MM-DD'));
    this.setState({to});

    //close:
    this.closeDialog();
  };

  handleFromFocus = () => {
    this.setState({
      focusedFrom: true,
      title: "Select Depart Date"
    })
  };

  handleFromBlur = () => {
    this.setState({
      focusedFrom: false
    })
  };

  handleToFocus = () => {
    this.setState({
      focusedTo: true,
      title: "Select Return Date"
    })
  };

  handleToBlur = () => {
    this.setState({
      focusedTo: false
    })
  };

  openDialog = () => {
    this.setState({
      openDialog: true
    })
  };

  closeDialog = () => {
    this.setState({
      openDialog: false
    })
    this.handleFromBlur();
    this.handleToBlur();
  };

  openDialogFrom = () => {
    this.handleFromFocus();
    this.openDialog();
  };

  openDialogTo = () => {
    this.handleToFocus();
    this.openDialog();
  };

  formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  
  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div>
      <div className="InputFromTo" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
      <div style={{position: 'relative'}}>
          <DateRangeContainer onMouseDown={this.openDialogFrom}>
            <DateRangeInputLabel>{"Depart Date"}</DateRangeInputLabel>
            <CustomInput value={this.formatDate(from)} readOnly/>
          </DateRangeContainer>
      </div>
      <span className="InputFromTo-to">
      <div style={{position: 'relative'}}>
        <DateRangeContainer onMouseDown={this.openDialogTo}>
          <DateRangeInputLabel>{"Return Date"}</DateRangeInputLabel>
          <CustomInput value={this.formatDate(to)} readOnly/>
        </DateRangeContainer>
      </div>
      </span>
      {(this.state.focusedFrom || this.state.focusedTo) &&
      <FullScreenDialog 
        title={this.state.title} 
        onClose={this.closeDialog} 
        open={this.state.openDialog} 
        isMobile={this.props.isMobile}
        style={{padding: 0, borderTop: "none",  borderBottom: "none"}}
      >
       {
         this.state.focusedFrom && 
         <DateReangeInline>
          <DayPicker
            className="Selectable"
            selectedDays={[from, { from, to }]}
            disabledDays = {[{before: new Date()}]}
            modifiers={modifiers}
            onDayClick={this.handleFromDayClick}
            numberOfMonths={12}
            canChangeMonth={false}
          />
         </DateReangeInline>
       }
      {
         this.state.focusedTo && 
         <DateReangeInline>
          <DayPicker
            className="Selectable"
            selectedDays={[from, { from, to }]}
            disabledDays = {[{before: from}, {before: new Date()}]}
            modifiers={modifiers}
            onDayClick={this.handleToDayClick}
            numberOfMonths={12}
            canChangeMonth={false}
            fromMonth= {from}
          />
        </DateReangeInline>
       }
      </FullScreenDialog> }
        <Helmet>
          <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #2196f3;
}

  .DayPicker-Caption > div {
    color: #0072c6;
  }

  .DayPicker-Day--today {
    color: #0072c6;
  }

  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .Selectable .DayPicker-wrapper {
    font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue", Helvetica,Arial,"Lucida Grande",sans-serif;
  }

  .Selectable .DayPicker-Month {
    width: 100%;
    margin: 0 2em;
    margin-top: 1em;
  }

  .Selectable .DayPicker-Day {
    outline: none;
  }

  .InputFromTo-to input{
    border-left: none;
  }

`}</style>
        </Helmet>
      </div>
      </div>
    );
  }
}


export default MobileDateRangePicker;