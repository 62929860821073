import React from 'react'
import {connect} from 'react-redux'
import AutoComplete from "../AutoComplete";
import {setOrigin} from "../../../actions/SetOrigin";
import {setDisplayValue} from "../../../actions/SetDisplayValue";
import {SET_ORIGIN_DISPLAY} from "../../../constants";
import {fetchDestinations} from  "../../../actions/FetchDestinations";
import {setFieldValidation} from  "../../../actions/SetValidation";


const mapStateToProps = (state) => {
    return {
        value: state.queryParams !== undefined ? state.queryParams.origin : '',
        className: 'origin',
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
        displayValue: (state.queryParams && state.queryParams.originDisplay),
        originCityName: (state.queryParams && state.queryParams.originCityName),
        originStateName: (state.queryParams && state.queryParams.originStateName),
        originCountryName: (state.queryParams && state.queryParams.originCountryName),
        originCountryFipsCode: (state.queryParams && state.queryParams.originCountryFipsCode)
    }
};


function mapDispatchToProps(dispatch) {

    return {
        setValue: (value,originCityName,originStateName,originCountryName,originCountryFipsCode) => dispatch(setOrigin(value,originCityName,originStateName,originCountryName,originCountryFipsCode)),
        setDisplayValue: (value) => dispatch(setDisplayValue(SET_ORIGIN_DISPLAY,value)),
        fetchDestinations: (value) => dispatch(fetchDestinations(value)),
        setFieldValidation: (isValid, msg) => dispatch(setFieldValidation(isValid, msg, 'origin')),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete);
