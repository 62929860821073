import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    render() {
        return (
            <div className="footer">
                <div className="footer-menu-container">
                    <ul className="footer-list">
                        <li className="footer-item">
                            <Link to="/privacy" target={"_blank"}> Privacy Policy</Link>
                        </li>
                        <li className="footer-item">
                            <Link to="/terms" target={"_blank"}> Terms of Use</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-copyright">
                    &copy;{(new Date().getFullYear())} lastmin-flights.com
                </div>
            </div>
        )
    }
}

export default Footer;