import React from 'react';
import styled from 'styled-components';
import Station from "./Station";
import DateTime from "./DateTime";
import Duration from "./Duration";
import Carrier from "./Carrier";
import TimeLine from "./TimeLine";

// todo: extract font styling
const SegmentDetailsContainer = styled.div`

`;

// todo: extract font styling. fix padding-right and margin- bottom
const SegmentCarrier = styled.div`
    display: flex; 
    align-items: center; 
    padding-left: 5rem; 
    margin-bottom: .75rem; 
    color: #68697f
    font-size: .75rem; 
    line-height: 1.125rem; 
    letter-spacing: normal; 
    
`;

const ItineraryDetails = styled.div`
    display: flex;
    align-items: center; 
`;

const FlightNumber = styled.div`
`;


const ItineraryTimes = styled.div`
    display: flex; 
    flex-direction: column; 
    flex-shrink: 0; 
    justify-content: space-between; 
    align-items: flex-start; 
    margin-right: .75rem; 
`;

const Segments=styled.div`
    display: flex;
    min-height: 3.75rem;
    flex-grow: 1;
`;

const ItineraryStations = styled.div`
    display: flex; 
    flex-direction: column; 
    flex-grow: 2; 
    justify-content: space-between; 
    align-items: flex-start; 

`;
//props:
/*
    1. departure time
    2. origin station
    3. arrivaltTime
    4. ArrivalStation

 */

class SegmentDetails extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {
        return (
            <SegmentDetailsContainer>
                <SegmentCarrier>
                    <Carrier details={this.props.carrier}/>
                    <FlightNumber>{`${this.props.carrier[0].Code}${this.props.flightNumber}`}</FlightNumber>
                </SegmentCarrier>
                <ItineraryDetails>
                    <Duration duration={this.props.duration}/>
                    <Segments>
                        <TimeLine/>
                        <ItineraryTimes>
                            <DateTime dateTime={this.props.departure} format="LT"/>
                            <DateTime dateTime={this.props.arrival} format="LT"/>
                        </ItineraryTimes>
                        <ItineraryStations>
                            <Station station={this.props.originStation} full/>
                            <Station station={this.props.destinationStation} full/>
                        </ItineraryStations>
                    </Segments>
                </ItineraryDetails>
            </SegmentDetailsContainer>
        )
    }


}

export default SegmentDetails;