import React from 'react';
import styled from "styled-components";
import FlightsSearchForm from "../FlightsSearchForm";
import {connect} from "react-redux";
import DateTime from "../DateTime";

const SummaryContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    background: #fff;
    margin: 0 auto;
    color: #333;
    box-sizing: border-box;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
    Helvetica, Arial, "Lucida Grande", sans-serif;
    // margin-top: 14px;
    font-weight: 500;
    color: rgb(82, 76, 97);

`;

const EditContainer = styled.div`
    justify-self: end;
`;

const ContentFirstItem = styled.div`
    margin-bottom: 5px;
    text-transform: uppercase;
`;

const renderDateInput = (oneWay, startDate, endDate) => {
    if (oneWay && startDate) {
        return (
            <div>
                <DateTime dateTime={startDate} format={'ddd, MMM D'} style={{display: "inline-block"}} />
            </div>
        )
    }

    if (startDate && endDate) {
        return (
            <div>
                <DateTime dateTime={startDate} format={'ddd, MMM D'} style={{display: "inline-block"}} />
                <span> - </span>
                <DateTime dateTime={endDate} format={'ddd, MMM D'} style={{display: "inline-block"}} />
            </div>
        )
    }

    return (
        <div>
            <FlightsSearchForm/>
        </div>
    )
};

function Summary(props) {

    const origin = props.queryParams.origin;
    const destination = props.queryParams.destination;
    const startDate = props.queryParams.departDate;
    const endDate = props.queryParams.returnDate;
    const oneWay = Number(props.queryParams.oneWay) || 0;

    const getLabel = (firstItem, secondItem) => {
        return oneWay ? (firstItem ? firstItem : ""): (firstItem && secondItem ? `${firstItem} - ${secondItem}` : "");
    };

    if (origin && destination && startDate && endDate) {
        return (
            <SummaryContainer>
                <div>
                    <ContentFirstItem>
                        {getLabel(origin,destination)}
                    </ContentFirstItem>
                    {renderDateInput(oneWay, startDate, endDate)}
                </div>
                <EditContainer>
                    <FlightsSearchForm/>
                </EditContainer>
            </SummaryContainer>
        )
    }

    return (
        <EditContainer>
            <FlightsSearchForm/>
        </EditContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        queryParams: state.queryParams,
    }
};


export default connect(mapStateToProps, null)(Summary);
