import axios from "axios";
import {sessionTokenUpdate} from "./FetchDeals";


export const recordSearch = (searchParameters) => {

    return (dispatch, getState) => {
        const queryParams = {
            destination: searchParameters.destination,
            origin: searchParameters.origin,
            depart_date: searchParameters.departDate,
            return_date: searchParameters.returnDate,
            adults: searchParameters.adults,
            cabin: searchParameters.cabin.toLowerCase(),
            one_way: searchParameters.oneWay,
            children: searchParameters.children,
            _token: getState().token,
        };

        return axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/record/search', {
            params: { ...queryParams}
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(sessionTokenUpdate(response.headers._token));
                    return response.data;
                } else {
                    throw response.message;
                }
            })
            .catch(error => {
                throw error;
            });
    };

};


