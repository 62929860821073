import React from 'react';

import styled from 'styled-components';
import _ from 'lodash';

const CarrierContainer = styled.div`
    display: flex;
    align-items: center; 
`;

const CarrierItem = styled.div`
    display: flex; 
    align-items: center; 
`;

const CarrierDisplayName = styled.span`
    
`;

const CarrierIcon = styled.img`
    width: ${props => props.large ? "2.5rem" : "1.5rem"}; 
    height: ${props => props.large ? "2.5rem" : "1.5rem"}; 
    border-radius: .1875rem;
`;

class Carrier extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const carriers = !_.isEmpty(this.props.details)  ? this.props.details : [];

        let carrierDisplayName = "";
        let carrierCode = "";

        if (carriers.length > 1) {
            carrierDisplayName = carriers.reduce((carrier, carry) =>
                !carry || carry.Name === carrier.Name ? carry.Name : 'Multiple Airlines'
            );
            carrierCode = carriers.reduce((carrier, carry) =>
                !carry || carry.Code === carrier.Code ? carry.Code : 'default'
            );
        }
        else {
            if(carriers.length > 0){
                carrierDisplayName = carriers[0].Name;
                carrierCode = carriers[0].Code;
            } 
        }


        let carrierDisplayImage = `https://images.kiwi.com/airlines/64x64/${carrierCode}.png`;

        return (
            <CarrierContainer>
                    <CarrierItem>
                        <CarrierIcon {...this.props} src={carrierDisplayImage} alt={carrierDisplayName}/>
                    </CarrierItem>
                {this.props.iconOnly ? "" : <CarrierDisplayName>{`\u00A0${carrierDisplayName}\u00A0`}</CarrierDisplayName>}
            </CarrierContainer>
        )
    }


}

export default Carrier;