import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CustomButton from './CustomButton';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import styled from "styled-components";


const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#fff',
    fontFamily: ["HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue","Helvetica","Arial","Lucida Grande","sans-serif"].join(','),
    color: '#0072c6',
    borderRadius: '0',
    textTransform: "capitalize",
    fontSize: "0.9rem",
    boxShadow: 'none',
    borderRight: '1px #9e9e9e73 solid',


    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fff',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}));


const Container = styled.div`
  border-bottom: 1px #9e9e9e73 solid;
  border-top: 1px #9e9e9e73 solid;
`;


export default function FiltersButton(props) {
  const classes = useStyles();
  return (
    <Container>
    <CustomButton classes={classes} {...props} icon={<FilterListTwoToneIcon />}></CustomButton>
    </Container>
  );
}