import React from 'react';
import Filters from "../Filters";
import DealsList from "../DealsList";
import Banners from "../Banner/Container/Banners";
import styled from 'styled-components';
import {connect} from 'react-redux'



const ResultsContainer = styled.div`
    display: grid;
    grid-template-columns: 20% 50% 23%;
    grid-column-gap: 3rem;

    @media only screen and (max-width: 768px){
        grid-template-columns: 100%;
    }
`;

const mapStateToProps = (state) => {
    return {
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile)),
    }
};

class Results extends React.Component {

    render() {
        return (
            <ResultsContainer>
                <Filters style={{width: 'auto', height: "fit-content"}}/>
                <DealsList/>
                {!this.props.isMobile && <Banners/>}
            </ResultsContainer>
        )
    }
}

export default connect(mapStateToProps, null)(Results);
