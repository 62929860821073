import React from 'react';
import Autosuggest from 'react-autosuggest';
import axios from "axios/index";
import styled from "styled-components";
import _ from 'lodash'
import FullScreenDialog from "../Dialog/FullScreenDialog";
import FlightIcon from '@material-ui/icons/Flight';
import FlightLandRoundedIcon from '@material-ui/icons/FlightLandRounded';
import FlightRoundedIcon from '@material-ui/icons/FlightRounded';
import FlightTakeoffRoundedIcon from '@material-ui/icons/FlightTakeoffRounded';

const AutoCompleteContainer = styled.div`
    height: 70px; 
    box-sizing : border-box;

        .react-autosuggest__container {
          position: relative;
        }
        
        .react-autosuggest__suggestions-container {
          display: none;
        }
        
        .react-autosuggest__suggestions-container--open {
          display: block;
          position: absolute;
          top: 75px;
          width: 400px;
          border: 1px solid #aaa;
          font-weight: 400; 
          font-size: 1rem;
          line-height: 1.5rem; 
          color: #524c61;
          border: 1px solid #e5ebf0;
          border-color: transparent;
          box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
          background-color: #fff;
          font-family: Helvetica, sans-serif;
          font-weight: 300;
          font-size: 16px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          z-index: 2;

          @media only screen and (max-width: 768px){
            z-index: 100;
            background-color: #fff;
            width: 100%;
            box-shadow: none;
            font-size: 0.85rem;
            box-sizing: border-box;
            display: block !important;
         }
        }
        
        .react-autosuggest__suggestions-list {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }
        
        .react-autosuggest__suggestion {
          cursor: pointer;
          padding: 10px 20px;  
          border-bottom: 1px solid #e5ebf0;
        }
        
        .react-autosuggest__suggestion--highlighted {
            background-color: #2196f3;
            color: #fff;
        }

`;

const AutoCompleteInputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
`;

const AutoCompleteLabelAnchor = styled.div`
    position: relative; 
`;


const CustomInput = {
    padding: '42px 0 9px 15px',
    fontSize: '18px',
    fontWeight: '500',
    border: 'none',
    width: '100%',
    borderRight: '1px #9e9e9e73 solid',
    boxSizing: 'border-box',
    outline: 'none',
};

const MobileCustomInput = {
    padding: '42px 0 9px 15px',
    fontSize: '18px',
    fontWeight: '500',
    border: 'none',
    width: '90%',
    margin: "0 5%",
    borderRight: 'none',
    borderBottom: "1px #9e9e9e73 solid",
    boxSizing: 'border-box',
    outline: 'none',
};

const AutoCompleteWrapper = styled.div`
   
    @media only screen and (max-width: 768px){
        height: 70px; 
        box-sizing : border-box;
     }
`;


const CustomAutoCompleteInput = styled.input`
        padding: 42px 0 9px 15px;
        font-size: 18px;
        font-weight: 500;
        border: none;
        width: 100%;
        border-right: 1px #9e9e9e73 solid;
        box-sizing: border-box;
        outline: none; 

        @media only screen and (max-width: 768px){
            border: 1px #9e9e9e73 solid;
            border-left: ${props => props.className === 'destination' ? "none" : '1px #9e9e9e73 solid'};
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

`;

const DialogAutoCompleteInputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
    font-family: Helvetica, sans-serif;
`;

const SuggestionContainer = styled.div`
    display: grid;
    grid-template-columns: 10% 90%;
    justify-self: center; 
    align-items: start;
    line-heigt: 1;
`;



const getSuggestionValue = suggestion => {
    return `${suggestion.entity.cityName} (${suggestion.entity.threeCode})`;
};


const renderSuggestion = suggestion => (
    <div>
        <SuggestionContainer>
            <span><FlightRoundedIcon fontSize={"small"} style={{color: "#9e9e9e73"}}/></span>
            <span >{`${suggestion.entity.cityName}, ${suggestion.entity.countryName} - ${suggestion.entity.name} (${suggestion.entity.threeCode})`}</span>
        </SuggestionContainer>
    </div>
);


class AutoComplete extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: [],
            dialogOpen: false,
            onInit : true,
            displayValue: '',
            cityName: '',
            stateName: '',
            countryName: '',
            originCityName: ''
        };
    }


    componentDidMount(){
        this.setState({
            value: this.props.displayValue || '',
            displayValue: this.props.displayValue || '',
            cityName: this.props.cityName || '',
            stateName: this.props.stateName || '',
            countryName: this.props.countryName || '',
            countryFipsCode: this.props.countryFipsCode || '',
            originCityName: this.props.originCityName,
            onInit: false
        });
    }

    componentDidUpdate(){
        if (this.props.value && this.props.value === this.props.exclude) {
            this.props.setValue('','','','','');
            this.props.setDisplayValue('');
            this.setState({
                value: '',
                displayValue: '',
                cityName: '',
                stateName: '',
                countryName: '',
                countryFipsCode: '',
                onInit: false
            });
            this.props.setFieldValidation(true,'');
        }

        if(this.props.isMobile){
            this.input.focus();
        }
    }
    //
    // setValueFromUrl(){
    //     debugger;
    //     if(this.props.value && !this.state.value){
    //         if(this.props.isMobile && this.props.displayValue){
    //             this.setState({
    //                 value: this.props.displayValue,
    //                 displayValue: this.props.displayValue,
    //                 onInit: false
    //             });
    //             return;
    //         }
    //         const inputValue = this.props.value.trim().toLowerCase();
    //         this.props.fetchDestinations(inputValue)
    //             .then(jsonResponse => {
    //                 const suggestion =  jsonResponse.find(item => item.entity.threeCode.includes(inputValue));
    //                 this.setState({
    //                     value:  `${suggestion.entity.cityName} (${suggestion.entity.threeCode})`,
    //                     displayValue: `${suggestion.entity.cityName} (${suggestion.entity.threeCode})`,
    //                     onInit: false
    //                 });
    //                 this.props.setDisplayValue(`${suggestion.entity.cityName} (${suggestion.entity.threeCode})`);
    //             })
    //             .catch(error => {
    //                 throw error;
    //             });
    //
    //     }
    // }

    handleChange = (event, {newValue}) => {
        this.setState({
            value: newValue,
        });
    };

    handleFocus = (event) => {
        // event.target.setSelectionRange(0, this.state.value.length); todo: should work in safari
        event.target.select();
    };

    onSuggestionsFetchRequested = ({value, reason}) => {

        if (reason === 'input-focused' || value.length < 3 ) {
            return Promise.resolve();
        }

        this.props.fetchDestinations(value)
            .then(jsonResponse => {
                this.setState({
                    suggestions: jsonResponse
                });
                return jsonResponse;
            })
            .catch(error => {
                throw error;
            });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.setValue(suggestion.entity.threeCode,suggestion.entity.cityName,suggestion.entity.stateName,suggestion.entity.countryName,suggestion.entity.countryFipsCode);
        this.props.setDisplayValue(`${suggestion.entity.cityName} (${suggestion.entity.threeCode})`);
        this.setState({
            value:  `${suggestion.entity.cityName} (${suggestion.entity.threeCode})`,
            displayValue: `${suggestion.entity.cityName} (${suggestion.entity.threeCode})`,
            cityName: `${suggestion.entity.cityName}`,
            stateName: `${suggestion.entity.stateName}`,
            onInit: false
        });
        this.props.setFieldValidation(true,'');
        this.closeDialog();
    };

    closeDialog = () => {
        this.setState({
            dialogOpen: false
        })
    };

    openDialog = () => {
        this.setState({
            dialogOpen: true
        })
    };

    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.input = autosuggest.input;
        }
    };

    getInputStyle = () => {
        return this.props.isMobile ? MobileCustomInput : CustomInput;
    };

    // function shouldRenderSuggestions(value) {
    //     return value.trim().length > 2;
    // }

    handleBlur = (event, { highlightedSuggestion }) => {

        const value = this.state.value;

        if(!this.props.isMobile && this.state.value !== this.props.value && this.state.value !== this.props.displayValue){
            if(!value || value.length < 3 || !highlightedSuggestion){
                this.props.setFieldValidation(false,'is required');
                this.setState({
                    value:  '',
                    displayValue: ''
                });
            }
            else{
                this.setState({
                    value:  `${highlightedSuggestion.entity.cityName} (${highlightedSuggestion.entity.threeCode})`,
                    displayValue: `${highlightedSuggestion.entity.cityName} (${highlightedSuggestion.entity.threeCode})`,
                    cityName:`${highlightedSuggestion.entity.cityName}`,
                    cityState:`${highlightedSuggestion.entity.stateName}`
                });
                this.props.setFieldValidation(true,'');
            }
        }

    };


    render() {

        let {value, suggestions} = this.state;

        const inputProps = {
            placeholder: 'City or Airport',
            value,
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onClick: this.handleFocus,
            onBlur: this.handleBlur,
            style: this.getInputStyle(),
        };

        return (
            //displayValue is in the form
            <AutoCompleteWrapper onMouseDown={this.openDialog}>
                {this.props.isMobile && <AutoCompleteLabelAnchor>
                    <AutoCompleteInputLabel>{this.props.label}</AutoCompleteInputLabel>
                    <CustomAutoCompleteInput value={this.state.displayValue} readOnly className={this.props.className} />
                </AutoCompleteLabelAnchor>}
                <FullScreenDialog
                    title={this.props.label}
                    onClose={this.closeDialog}
                    open={this.state.dialogOpen}
                    isMobile={this.props.isMobile}
                    style={{padding: 0, borderTop: "none",  borderBottom: "none"}}
                >
                    <AutoCompleteContainer>
                        <AutoCompleteLabelAnchor>
                            <DialogAutoCompleteInputLabel>{this.props.label}</DialogAutoCompleteInputLabel>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                onSuggestionSelected={this.onSuggestionSelected}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                focusInputOnSuggestionClick={false}
                                inputProps={inputProps}
                                ref={this.storeInputReference}
                                selectOnTab={true}
                            />
                        </AutoCompleteLabelAnchor>
                    </AutoCompleteContainer>
                </FullScreenDialog>
            </AutoCompleteWrapper>

        );
    }
}

export default AutoComplete;