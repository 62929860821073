import React from 'react';
import Leg from './Leg';
import Price from './Price';

import styled from 'styled-components'
import LegDetails from "./LegDetails";
import DealSkeleton from "./Skeletons/Deal";
import _ from 'lodash';

//max-width: 650px;
const DealContainer = styled.div`
    margin: 0 auto; 
    margin-bottom: 15px; 
    display: grid; 
    grid-auto-columns: 70% 30%; 
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400; 
    font-size: 1rem;
    line-height: 1.5rem; 
    color: #524c61;
    border: 1px solid #e5ebf0;
    border-color: transparent;
    box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    background-color: white;

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const InboundLegContainer = styled.div`
`;

const OutboundLegContainer = styled.div`
`;

const PriceContainer = styled.div`
    grid-area: 1 / 2 / 2 / 3; 
    align-self: center; 

    @media only screen and (max-width: 768px) {
        padding: 0.5em 0;
    }
`;

const DetailedLegs = styled.div`
    grid-area: 2 / 1 / 3 / 3;
    padding: 1.5rem
    background: #f3f5f7;
`;

const Legs = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    display: flex; 
    flex-direction: column; 
    border-right: 1px solid #e5ebf0; 
    
    @media only screen and (max-width: 768px) {
        border-bottom: 1px solid lightgray;
        border-right: none;
    }  
`;

class Deal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false
        }
    }

    showDetails() {
        this.setState({
            showDetails: !this.state.showDetails
        })
    }

    render() {
        const inboundLeg = this.props.info.InboundLeg;
        const outboundLeg = this.props.info.OutboundLeg;
        // const inboundSegments = this.props.info.InboundLeg.SegmentIds;
        // const outboundSegments = this.props.info.OutboundLeg.SegmentIds;
        const inboundSegments = !_.isEmpty(inboundLeg) ? inboundLeg.Segments : [];
        const outboundSegments = outboundLeg.Segments;

        this.props.info.PricingOptions[0].durationIn = _.isEmpty(this.props.info.InboundLeg.Duration) ? {} : this.props.info.InboundLeg.Duration
        this.props.info.PricingOptions[0].durationOut = _.isEmpty(this.props.info.OutboundLeg.Duration) ? {} : this.props.info.OutboundLeg.Duration
        
        return (
            <DealContainer>
                <Legs onClick={() => this.showDetails()}>
                    <OutboundLegContainer>
                        <Leg type="outbound" legData={outboundLeg}/>  
                    </OutboundLegContainer>
                    {!_.isEmpty(inboundLeg) &&
                        <InboundLegContainer>
                            <Leg type="inbound" legData={inboundLeg}/>
                        </InboundLegContainer>
                    }
                </Legs>
                <PriceContainer>
                    <Price
                        data={this.props.info.PricingOptions}
                        dealToken={this.props.info.OutboundLegId + this.props.info.InboundLegId}
                        travelers={this.props.travelers}
                        durationIn={this.props.info.InboundLeg.Duration}
                        durationOut={this.props.info.OutboundLeg.Duration}
                    />
                </PriceContainer>
                <DetailedLegs style={this.state.showDetails ? {disply: 'block'} : {display: 'none'}}>
                    <LegDetails legData={outboundLeg} segments={outboundSegments}/>
                    {!_.isEmpty(inboundLeg) && <LegDetails legData={inboundLeg} segments={inboundSegments}/> }
                </DetailedLegs>
            </DealContainer>


        )
    }


}

export default Deal;