import React from 'react'
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

const TravelerCounterContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    padding: 0.1rem
`;

const Description = styled.span`
    font-size: 0.8rem;
`;

const Title = styled.div`
    font-weight: 600;
    color: #2196f3;
`;

const CounterContainer = styled.div`
    justify-self: end; 
    align-self: center;
`;

const TitleContainer = styled.div`
    align-self: center; 
`;

const iconStyling = {
    fontSize: '1.5rem',
};

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));


export default function TravelerCounter(props) {

    const classes = useStyles();

    const type = props.type;
    const title = props.title;
    const count = props.count;
    const description = props.description;
    const increment = props.increment;
    const decrement = props.decrement;


    return (
        <TravelerCounterContainer>
            <TitleContainer>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </TitleContainer>
            <CounterContainer>
                <IconButton
                    className={classes.button}
                    aria-label="remove"
                    name="decrement"
                    onClick={() => decrement(type)}
                >
                <RemoveCircleOutlineOutlinedIcon style={iconStyling}/>
                </IconButton>
                {count}
                <IconButton
                    className={classes.button}
                    aria-label="add"
                    name="increment"
                    onClick={() => increment(type)}
                >
                <AddCircleOutlineOutlinedIcon style={iconStyling}/>
                </IconButton>
            </CounterContainer>
        </TravelerCounterContainer>
    )
}


