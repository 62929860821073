import {SET_SEARCH_INTERVAL} from "../constants";

export const setSearchInterval = () => dispatch => {

    dispatch({
        type: SET_SEARCH_INTERVAL
    });
    return Promise.resolve()

};
