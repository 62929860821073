import React from 'react';
import moment from 'moment'
import styled from 'styled-components';

const DateTimeContainer = styled.div`
`;

class DateTime extends React.Component {
    constructor(props) {
        super(props)
    }

    formatDate = (dateTime, format) => {
        return moment(dateTime).format(format);
    };

    render() {
        return (
            <DateTimeContainer style={{...this.props.style}} >
                {this.formatDate(this.props.dateTime, this.props.format)}
            </DateTimeContainer>

        )
    }


}

export default DateTime;