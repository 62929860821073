import axios from "axios";
import queryString from "query-string";
import {sessionTokenUpdate} from "./FetchDeals";


export const recordLanding = (urlQueryString) => {

    const urlParameters = queryString.parse(urlQueryString);


    return (dispatch) => {
        return axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/record/landing', {
            params: {
                client_id: urlParameters.cid,
                click_id: urlParameters.gclid,
                campaign_name: urlParameters.utm_campaign
            }
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(sessionTokenUpdate(response.headers._token));
                    return response.data;
                } else {
                    throw response.message;
                }
            })
            .catch(error => {
                throw error;
            });
    }
};
