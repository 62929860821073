import React from 'react';
import styled from "styled-components";
import Travelers from "../Travelers/Travelers";
import {connect} from 'react-redux';
import {setTravelers} from "../../actions/SetTravelers";
import {setCabinClass} from "../../actions/SetCabinClass";
import _ from "lodash";
import FullScreenDialog from "../Dialog/FullScreenDialog";

const TravelersAndCabinClassContainer = styled.div`
    height: 70px; 
    box-sizing : border-box;
`;

const TravelersAndCabinClassInputLabel = styled.label`
    position: absolute; 
    font-size: 16px; 
    color: #8b8f9f;
    font-weight: 100; 
    left: 15px;
    top: 10px; 
    border: none; 
    margin: 0; 
    z-index: 2;
`;

const TravelersAndCabinClassAnchor = styled.div`
    position: relative; 
`;


const CustomInput = styled.input`
        padding: 42px 0 9px 15px;
        font-size: 18px;
        font-weight: 500;
        border: none;
        width: 100%;
        border-right: 1px #9e9e9e73 solid;
        box-sizing: border-box;
        outline: none; 

        @media only screen and (max-width: 768px){
            // border-right: none;
            border: 1px #9e9e9e73 solid;
            border-top: none;
            font-size: 16px;
         }
`;

function mapDispatchToProps(dispatch) {
    return {
        save: payload => {
            dispatch(setTravelers({
                children: payload.children,
                adults: payload.adults
            }));
            dispatch(setCabinClass(payload.cabin));
        }
    }
}

const mapStateToProps = state => {
    return {
        queryParams: state.queryParams,
        isMobile: !!(state.queryParams && Number(state.queryParams.isMobile))
    }
};

class ConnectedTravelersAndCabinClass extends React.Component {
    constructor(state) {
        super(state);
        this.state = {
            focused: false,
            onFocus: true,
            value: '',
            adults: 1,
            children: 0,
            cabin: 'Economy'
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps, this.props) && this.props.queryParams !== undefined) {

            this.setValuesFromQuery();
        }
    }

    componentDidMount() {
        this.setValuesFromQuery();
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener("keydown", this.handleExitOnKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.addEventListener("keydown", this.handleExitOnKeyDown, false);
    }

    setValuesFromQuery = () =>{
        this.setState({
            adults: this.props.queryParams.adults,
            children: this.props.queryParams.children,
            cabin: this.props.queryParams.cabin
        }, this.updateValue);
    };

    setWrapperRef = node => { this.wrapperRef = node };

    handleClickOutside = event => {
        if(this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.props.isMobile)
            this.handleBlur()
    };

    handleExitOnKeyDown = event => {
        if(event.key === "Escape" || event.key === "Tab")
            this.handleBlur()
    };

    toggleDropDown = () => {(this.state.focused) ? this.handleBlur() : this.handleFocus()};

    openDropdown = () => { if(this.state.onFocus) this.handleFocus();};

    handleFocus = () => {
        this.setState({
            focused: true
        })
    };

    disableOnFocus(){
        this.setState({
            onFocus: false
        })
    }

    enableOnFocus(){
        this.setState({
            onFocus: true
        })
    }


    handleBlur = () => {
        this.setState({
            focused: false
        }, this.saveSelection)
    };

    saveSelection = () => {
        this.props.save(this.state);
    };

    updateValue = () => {
        this.setState({
            value: `${this.state.adults + this.state.children} Travelers, ${this.state.cabin}`
        })
    };

    handleIncrement = (type) => {

        this.setState({
            [type]: this.state[type] + 1
        }, this.updateValue)

    };

    handleDecrement = (type) => {

        if (type === "adults") {
            if (this.state[type] > 1) {
                this.setState({
                    [type]: this.state[type] - 1
                }, this.updateValue)
            }
        } else {
            if (this.state[type] > 0) {
                this.setState({
                    [type]: this.state[type] - 1
                }, this.updateValue)
            }
        }

    };

    handleCarouselChange = (cabin) => {
        this.setState({cabin: cabin}, this.updateValue)
    };


    render() {
        return (
            <TravelersAndCabinClassContainer ref={this.setWrapperRef}>
                <TravelersAndCabinClassAnchor
                    onMouseDown={()=>{this.disableOnFocus()}}
                    onMouseUp={()=>{this.enableOnFocus()}}
                    onClick={()=>{this.toggleDropDown()}}
                >
                    <TravelersAndCabinClassInputLabel>{this.props.label}</TravelersAndCabinClassInputLabel>
                    <CustomInput value={this.state.value} readOnly onFocus={()=>{this.openDropdown()}}/>
                </TravelersAndCabinClassAnchor>
                {this.state.focused &&
                <FullScreenDialog
                    title={"Travelers"}
                    onClose={this.handleBlur}
                    open={this.state.focused}
                    isMobile={this.props.isMobile}
                    style={{padding: 0, borderTop: "none",  borderBottom: "none"}}
                >
                    <Travelers
                        close={this.handleBlur}
                        increment={this.handleIncrement}
                        decrement={this.handleDecrement}
                        onCarouselChange={this.handleCarouselChange}
                        children={this.state.children}
                        adults={this.state.adults}
                        isMobile={this.props.isMobile}
                        cabin={this.state.cabin}
                    />
                </FullScreenDialog>
                }
            </TravelersAndCabinClassContainer>

        );
    }
}

const TravelersAndCabinClass = connect(mapStateToProps, mapDispatchToProps)(ConnectedTravelersAndCabinClass);

export default TravelersAndCabinClass;